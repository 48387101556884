import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
//i18n
import { useTranslation, withTranslation } from "react-i18next";
import AvFieldSelect from "components/Common/AvFieldSelect";
import {
  LANGUAGES,
  INDUSTRIES,
  ANNUAL_INCOME,
  SOURCE_OF_FUNDS,
  GENDER,
  YESNO,
  TRADING_EXPERIENCE,
  TRADING_GOAL,
  DEPOSIT_RANGE,
  TRADING_DURATION,
} from "common/data/dropdowns";
import {
  CLIENT_AGREEMENT,
  IB_AGREEMENT,
  COUNTRY_REGULATIONS,
  E_SIGNATURE,
} from "declarations";
import { submitIndProfile } from "store/general/auth/profile/actions";
import parse from "html-react-parser";
import { CloseButton } from "react-bootstrap";
import { CUSTOMER_SUB_PORTALS } from "common/constants";
import { createAccountRequest, getAccountTypesStart } from "store/actions";
// import AvFieldSelecvt from "components/Common/AvFieldSelect";
import Select from "react-select";
import AvFieldSelect2 from "components/Common/AvFieldSelect2";
import { useUserDataProfile } from "context/UserDataProfile";

const DOC = ["Driver's license", "ID card", "Residence permit", "Passport"];

function LeverageAwareModal({ isOpen, toggle }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} centered style={{ maxWidth: "500px" }}>
      <ModalHeader
        style={{
          border: "none",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <CloseButton
          onClick={toggle}
          style={{
            alignSelf: "flex-end",
            position: "absolute",
            right: 10,
            top: 10,
          }}
        />
        <div className="text-center p-3">
          <i
            className="fas fa-exclamation-triangle"
            style={{
              fontSize: "32px",
              color: "#d81618",
              marginBottom: "15px",
              display: "block",
            }}
          ></i>
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "1.5",
              color: "#333",
            }}
          >
            {t(
              "To complete your registration, please confirm that you understand the risks of trading leveraged products. Review your answers to proceed with your application"
            )}
          </h6>
        </div>
      </ModalHeader>
      <div
        className="modal-footer"
        style={{
          border: "none",
          padding: "1.5rem",
          display: "flex",
          justifyContent: "center",
          gap: "12px",
        }}
      >
        <Button
          color="secondary"
          onClick={toggle}
          style={{
            minWidth: "120px",
            padding: "8px 24px",
            fontWeight: "500",
            borderRadius: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={toggle}
          style={{
            backgroundColor: "#d81618",
            borderRadius: "10px",
            padding: "10px 30px",
            borderColor: "red",
          }}
        >
          Review
        </Button>
      </div>
    </Modal>
  );
}

function AccountRequestForm(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      getAccountTypesStart({
        forCp: true,
      })
    );
    return () => {
      dispatch(getAccountTypesStart());
    };
  }, []);

  const { accountTypes, submitting } = useSelector(
    (state) => state.forex.accounts
  );
  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [platformAccountTypes, setPlatformAccountTypes] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [accountCurrency, setSAccountCurrency] = useState([]);
  const [accountLeverages, setAccountLeverages] = useState("");
  const [accountTypeId, setAccountTypeId] = useState("");
  const [type, setType] = useState(null);

  // filter accounts based on account's type (live/demo)
  useEffect(() => {
    setFilteredAccountTypes(accountTypes?.filter((type) => (type?.platform || "")?.toLowerCase() === (selectedPlatform || "")?.toLowerCase()));
  }, [accountTypes, type, selectedPlatform]);

  // get unique platforms
  const platforms = accountTypes.length > 0 && [
    ...new Set(accountTypes.map((item) => item.platform)),
  ];
  const types = accountTypes.length > 0 && [
    ...new Set(accountTypes.map((item) => item.type)),
  ];
  const { clientData } = useSelector((state) => state.Profile);

  // filter accounts based on platform
  useEffect(() => {
    setPlatformAccountTypes(
      filteredAccountTypes?.filter(
        (account) => account.platform === selectedPlatform
      )
    );
  }, [selectedPlatform]);
  
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [promoLinkPlatforms, setPromoLinkPlatforms] = useState(null);
  // filter accounts based on account's type (live/demo)

  useEffect(() => {
    console.log("clientData?.promoLink", clientData?.promoLink);
    if (clientData?.promoLink) {
      let platformsSet = new Set();
      clientData?.promoLink?.forEach(promo => {
        platformsSet.add(promo?.accountType?.platform);
      });
      setPromoLinkPlatforms([...platformsSet]);
    }
  }, [clientData?.promoLink, selectedPlatform]);

  const handleValidSubmit = (event, values) => {
    values.leverage = accountLeverages || values.leverage;
    values.leverage = accountLeverages || values.leverage;
    props?.onSubmit(values);
  };

  return <>
    <AvForm
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, {
          ...v,
          promoLink: selectedPromo?._id
        });
      }}
    >
      {/* { clientData.promoLink && Object.entries(clientData.promoLink).length > 0 ? (
        types && <Row className="border rounded-3 p-3 my-1">
          <h5>{t("Account Type")}</h5>
          {types?.filter((type) => type === clientData?.promoLink?.accountType?.type)?.map((ty) =>
            <Col key={ty} md="6" className="gy-3"
              onClick={() => {
                document.getElementById(ty).click();
                setType(ty);
              }}
            >
              <div className="custom-radio"
                style={{
                  border: 0,
                }}>
                <Input
                  className="mt-0 me-2"
                  id={ty}
                  name="type"
                  type="radio"
                  value={ty}
                >
                </Input>
                <Label check for={ty}>
                  {t(ty)}
                </Label>
              </div>
            </Col>)}
        </Row>
      ) : (
        types && <Row className="border rounded-3 p-3 my-1">
          <h5>{t("Account Type")}</h5>
          {types?.map((ty) =>
            <Col key={ty} md="6" className="gy-3"
              onClick={() => {
                document.getElementById(ty).click();
                setType(ty);
              }}
            >
              <div className="custom-radio"
                style={{
                  border: 0,
                }}>
                <Input
                  className="mt-0 me-2"
                  id={ty}
                  name="type"
                  type="radio"
                  value={ty}
                >
                </Input>
                <Label check for={ty}>
                  {t(ty)}
                </Label>
              </div>
            </Col>)}
        </Row>
      )
      }
      {platforms &&
      <Row className="border rounded-3 p-3 gx-1">
        <h5>{t("Platforms")}</h5>
        {clientData?.promoLink && Object.entries(clientData.promoLink).length > 0 ? (
          <Col key={clientData?.promoLink?.accountType?.platform} md="6" className="gy-3 px-2">
            <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
              <Input
                className="mt-0 me-2"
                id={clientData?.promoLink?.accountType?.platform}
                name="platform"
                type="radio"
                value={clientData?.promoLink?.accountType?.platform}
                onClick={() => {
                  setSelectedPlatform(clientData?.promoLink?.accountType?.platform);
                }}
              />
              <Label check for={clientData?.promoLink?.accountType?.platform}>
                {t(clientData?.promoLink?.accountType?.platform)}
              </Label>
            </div>
          </Col>
        ) : (
          platforms.map((platform) => (
            <Col key={platform} md="6" className="gy-3 px-2">
              <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                <Input
                  className="mt-0 me-2"
                  id={platform}
                  name="platform"
                  type="radio"
                  value={platform}
                  onClick={() => {
                    setSelectedPlatform(platform);
                  }}
                />
                <Label check for={platform}>
                  {t(platform)}
                </Label>
              </div>
            </Col>
          ))
        )}
      </Row>
      }
      {selectedPlatform && (
        <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
          <Row className="p-3">
            <h5>{t("Account Type")}</h5>
            {clientData?.promoLink && Object.entries(clientData.promoLink).length > 0 ? (
              <Col key={clientData?.promoLink?.accountType?._id} md="6" className="gy-3 mt-3">
                <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                  <AvRadio
                    label={t(clientData?.promoLink?.accountType?.title)}
                    value={clientData?.promoLink?.accountType?._id}
                    onClick={() => {
                      setSAccountCurrency(clientData?.promoLink?.accountType?.currencies);
                    }}
                  />
                </div>
              </Col>
            ) : (
              filteredAccountTypes.length > 0 &&
              filteredAccountTypes.map((type) => (
                <Col key={type._id} md="6" className="gy-3 mt-3">
                  <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                    <AvRadio
                      label={t(type.title)}
                      value={type._id}
                      onClick={() => {
                        setSAccountCurrency(type.currencies);
                      }}
                    />
                  </div>
                </Col>
              ))
            )}
          </Row>
        </AvRadioGroup>
      )}
      {accountCurrency.length > 0 &&
        <Row className="border rounded-3 p-3 my-1">
          <AvRadioGroup name="currency" errorMessage={t("Currency is required")} required className="radio-group" >
            <Row className="radio-group " >
              <h5 className="mb-3">{t("Account Currency")}</h5>
              {accountCurrency.map((currency) =>
                <Col key={currency.currency} md="6" className="gy-3" >
                  <div className="custom-radio" 
                    onClick={() => {
                      document.getElementById(`radio-currency-${currency.currency}`).click();
                    }} >
                    <AvRadio label={t(currency.currency)} value={currency.currency} />
                  </div>
                </Col>
              )}
            </Row>
          </AvRadioGroup>
        </Row>}
      <Row className="border rounded-3 p-3 mt-3 gx-1">
        <h5 className="mb-3">{t("Select Leverage")}</h5>
        <AvField type="select" name="leverage" value={"1"} required>
          {clientData?.promoLink && Object.entries(clientData.promoLink).length > 0 ?
            clientData?.promoLink?.accountType?.leverages.map((leverage) => (
              <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
            )) : filteredAccountTypes?.[0]?.leverages.map((leverage) => (
              <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
            ))
          }
        </AvField>
      </Row> */}
      {platforms &&
        <Row className="border rounded-3 p-3 gx-1">
          <h5>{t("Platforms")}</h5>
          {clientData?.promoLink && promoLinkPlatforms?.length > 0 ? (
            promoLinkPlatforms?.map((promo) => (
              <Col key={promo} md="6" className="gy-3 px-2">
                <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                  <Input
                    className="mt-0 me-2"
                    id={promo}
                    name="platform"
                    type="radio"
                    value={promo}
                    onClick={() => {
                      setSelectedPlatform(promo);
                    }}
                  />
                  <Label check for={promo}>
                    {t(promo)}
                  </Label>
                </div>
              </Col>
            ))
          ) : (
            platforms.map((platform) => (
              <Col key={platform} md="6" className="gy-3 px-2">
                <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                  <Input
                    className="mt-0 me-2"
                    id={platform}
                    name="platform"
                    type="radio"
                    value={platform}
                    onClick={() => {
                      setSelectedPlatform(platform);
                    }}
                  />
                  <Label check for={platform}>
                    {t(platform)}
                  </Label>
                </div>
              </Col>
            ))
          )}
        </Row>
      }
      {selectedPlatform && (
        <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
          <Row className="p-3">
            <h5>{t("Account Type")}</h5>
            {selectedPlatform && clientData?.promoLink?.length > 0 ? (
              clientData?.promoLink?.map((promo) => (
                promo?.accountType?.platform === selectedPlatform && (
                  <Col key={promo?.accountType?._id} md="6" className="gy-3 mt-3">
                    <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                      <AvRadio
                        label={t(promo?.accountType?.title)}
                        value={promo?.accountType?._id}
                        onClick={() => {
                          setSAccountCurrency(promo?.accountType?.currencies);
                          setSelectedPromo(promo);
                        }}
                      />
                    </div>
                  </Col>
                )
              ))
            ) : (
              filteredAccountTypes.length > 0 &&
                      filteredAccountTypes.map((type) => (
                        <Col key={type._id} md="6" className="gy-3 mt-3">
                          <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                            <AvRadio
                              label={t(type.title)}
                              value={type._id}
                              onClick={() => {
                                setSAccountCurrency(type.currencies);
                              }}
                            />
                          </div>
                        </Col>
                      ))
            )}
          </Row>
        </AvRadioGroup>
      )}

      {/* {accountCurrency.length > 0 &&
        <Row>
          <AvRadioGroup name="currency" className="border rounded-3 mt-3" required>
            <Row className="p-3">
              <h5>{t("Account Currency")}</h5>
              {
                clientData?.promoLink ?
                  <Col md="6" className="gy-3">
                    <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                      <AvRadio label={t("USD")} value={"USD"} />
                    </div>
                  </Col>
                :
                  accountCurrency.map((currency) =>
                    <Col key={currency.currency} md="6" className="gy-3">
                      <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                        <AvRadio label={t(currency.currency)} value={currency.currency} />
                      </div>
                    </Col>
                  )
              }
            </Row>
          </AvRadioGroup>
        </Row>
      } */}
      <Row className="border rounded-3 p-3 mt-3 gx-1">
        <h5 className="mb-3">{t("Select Leverage")}</h5>
        <AvField type="select" name="leverage" value={"1"} required onChange={(e) => setAccountLeverages(e.target.value)}>
          {selectedPromo && Object.entries(selectedPromo).length > 0 ? (
            selectedPromo?.accountType?.leverages.map((leverage) => (
              <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
            ))
          ) : (
            filteredAccountTypes?.[0]?.leverages.map((leverage) => (
              <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
            ))
          )}
        </AvField>
      </Row>

      <Row className="justify-content-center">
        <div className="text-center w-100 my-2">
          <Button
            type="button"
            className="mx-2"
            style={{
              borderRadius: "10px",
              padding: "10px 30px",
            }}
            onClick={() => props?.back()}
          >{t("Go Back")}</Button>
          <Button
            disabled={submitting}
            type="submit"
            style={{
              backgroundColor: "#d81618",
              borderRadius: "10px",
              padding: "10px 30px",
              borderColor: "red",
            }}
            className="mx-2"
          >
            {t("Continue")}
          </Button>
        </div>
      </Row>
    </AvForm>
  </>;
}

const SubmitProfile = (props) => {
  const TinNumReasonOptions = [
    {
      label: "My Country does not provide TIN's to its residents",
      value: "My Country does not provide TIN's to its residents",
    },
    {
      label:
        "TIN not required (choose this if your country does not mandate TIN disclosure)",
      value:
        "TIN not required (choose this if your country does not mandate TIN disclosure)",
    },
    {
      label:
        "I am unable to obtain a TIN or equivalent (please explain below if this is the case)",
      value:
        "I am unable to obtain a TIN or equivalent (please explain below if this is the case)",
    },
  ];

  function isPositiveNumberOrAlphanumeric(value) {
    const isPositiveNumber =
      !isNaN(value) && parseInt(value) > 0 && value.trim() !== "";

    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);

    return isPositiveNumber || isAlphanumeric;
  }
  const [empStatus, setEmpStatus] = useState("");
  const [pageLoadTime, setPageLoadTime] = useState(null);
  const dispatch = useDispatch();
  const [tinNum, setTinNum] = useState("");
  const [haveTinNum, setHaveTinNum] = useState(true);
  const [tinNumReason, setTinNumReason] = useState("");
  const [profileForm, setProfileForm] = useState({});
  const [stage, setStage] = useState(1);
  const [tradingGoal, setTradingGoal] = useState("");
  const [otherTradingGoal, setOtherTradingGoal] = useState("");
  const [customTinNumReason, setCustomTinNumReason] = useState("");
  const [tinNumReasonErrorMessage, setTinNumReasonErrorMessage] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentNum, setDocumentNum] = useState(0);
  const [tinNumError, setTinNumError] = useState(false);
  const [tinNumErrorMessage, setTinNumErrorMessage] = useState("");
  const [leverageAware, setLeverageAware] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(true);
  const handleTinNumReasonChange = (selectedOption) => {
    setTinNumReason(selectedOption);
    setTinNumReasonErrorMessage("");
  };

  const { profile, submittingProfile, isClient } = useSelector((state) => ({
    profile: (state.Profile && state.Profile.clientData) || {},
    submittingProfile: state.Profile.submittingProfile,
    countries: state.dictionary.countries || [],
    isClient: state.Layout.subPortal === CUSTOMER_SUB_PORTALS.LIVE,
  }));

  const { t } = useTranslation();

  // useEffect(() => {
  //   setTinNum("");
  //   setTinNumError(false);
  // }, [haveTinNum]);

  useEffect(() => {
    if (props.isOpen) {
      setPageLoadTime(new Date().toISOString());
    }
  }, []);

  useEffect(() => {
    dispatch(
      getAccountTypesStart({
        forCp: true,
      })
    );
    return () => {
      dispatch(
        getAccountTypesStart({
          forCp: true,
        })
      );
    };
  }, []);
  const { profileData, setProfileData } = useUserDataProfile();
  const form = {
    1: (
      <>
        <LeverageAwareModal
          isOpen={leverageAware}
          toggle={() => setLeverageAware(false)}
        />
        <AvForm
          onValidSubmit={(e, v) => {
            if (v?.tradingProfile?.leverageAware === "no") {
              return setLeverageAware(true);
            }
            if (!haveTinNum && tinNumReason === "") {
              setTinNumReasonErrorMessage("TIN Reason is required");
              return;
            }
            if (haveTinNum && v.tinNum === "") {
              setTinNumError(true);
              setTinNumErrorMessage("TIN Number is required");
              return;
            }
            if (tinNumError) {
              return;
            }
            if (!haveTinNum) {
              v.tinNum = "";
            }

            v.declarations = [
              CLIENT_AGREEMENT,
              COUNTRY_REGULATIONS,
              E_SIGNATURE,
            ];
            v.submitProfileStartTime = pageLoadTime;
            v.tradingProfile.tradingGoal = otherTradingGoal
              ? otherTradingGoal
              : tradingGoal;
            delete v.agreement;
            delete v.regulations;
            delete v.signature;
            delete v.fullName;
            delete v.customTinNumReason;
            // if (Number.isNaN(v.tinNum) || Array.isArray(v.tinNum)) {
            //   v.tinNum = "";
            //   // delete v.tinNum;
            // } else {
            //   v.tinNum = tinNum + "0";
            //   console.log("type of TinNum", typeof v.tinNum);
            // }

            if (
              tinNumReason.value ===
              "I am unable to obtain a TIN or equivalent (please explain below if this is the case)"
            ) {
              v.tinNumReason = customTinNumReason;
            } else {
              v.tinNumReason = tinNumReason.value;
            }
            setProfileData({
              ...v,
              agreement:true,
              regulations:true,
              signature: true
            });
            setStage(2);
            setProfileForm(v);
          }}
        >
          <div className="d-flex flex-column gap-2">
            {/* first row */}
            <Row>
              <Col md="6">
                {/* <div className="mt-2">
                <AvFieldSelect
                  name="title"
                  type="text"
                  placeholder={t("Select Your Title")}
                  errorMessage={t("Title is required")}
                  validate={{ required: { value: true } }}
                  value={profile.title || ""}
                  label={t("Title")}
                  options={TITLES.map((obj) => {
                    return ({
                      label: obj.name,
                      value: obj.value
                    });
                  })}
                />
              </div> */}
                <div className="mt-2">
                  <AvFieldSelect2
                    name="language"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Language")}
                      </>
                    }
                    placeholder="Select language"
                    options={LANGUAGES?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.language}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Language is required",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2 ">
                  <AvField
                    name="email"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Email Address")}
                      </>
                    }
                    type="text"
                    defaultValue={profile.email}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            {/* second row */}
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvField
                    name="phone"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Mobile Phone Number")}
                      </>
                    }
                    placeholder={t("Enter Postal Code")}
                    type="text"
                    value={profile.phone}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="gender"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Gender")}
                      </>
                    }
                    placeholder="Select Gender"
                    options={GENDER?.map((item) => ({
                      label: `${item.label}`,
                      value: item.value,
                    }))}
                    value={profileData?.gender}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Gender is required",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            {/* third row */}
            <Row>
              <Col md="6">
                <div className="mt-2 ">
                  <AvField
                    name="fullName"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Full Name")}
                      </>
                    }
                    type="text"
                    defaultValue={profile.firstName + " " + profile.lastName}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2">
                  <AvField
                    name="dob"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Birthday")}
                      </>
                    }
                    placeholder={t("Enter Date of birth")}
                    type="date"
                    min={moment().subtract("110", "years").format("YYYY-MM-DD")}
                    max={moment().subtract("18", "years").format("YYYY-MM-DD")}
                    errorMessage={t("DOB is required")}
                    validate={{
                      required: { value: true },
                      dateRange: {
                        format: "YYYY-MM-DD",
                        start: {
                          value: moment()
                            .subtract("110", "years")
                            .format("YYYY-MM-DD"),
                        },
                        end: {
                          value: moment()
                            .subtract("18", "years")
                            .format("YYYY-MM-DD"),
                        },
                      },
                    }}
                    value={profileForm.dob}
                  />
                </div>
              </Col>
            </Row>
            {/* fourth row */}
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="documentType"
                    label={t("Document Type (optional)")}
                    placeholder={t("Select Your Document (optional)")}
                    options={DOC?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={profileData?.documentType}
                    // validate={{
                    //   required: {
                    //     value: true,
                    //     errorMessage: "Document Type is required",
                    //   },
                    // }}
                  />
                </div>
              </Col>
              <Col md="6">
                {/* <div className="mt-2">
                <AvField
                  name="city"
                  label={t("* City")}
                  placeholder={t("Enter Your City")}
                  type="text"
                  errorMessage={t("City is required")}
                  validate={{ required: { value: true } }}
                  value={profile.city}
                />
              </div> */}

                {/* <div className="mt-2">
                  <AvFieldSelect2
                    name="documentNum"
                    label={t("Document Number (optional)")}
                    placeholder={t("Enter Document Number")}
                    options={DOC?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={profileData?.documentNum}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Document Number is required",
                      },
                    }}
                  />
                </div> */}
                <div className="mt-2">
                  <AvField
                    name="documentNum"
                    type="number"
                    value={profile.documentNum}
                    label={<>{t("Document Number (Optional)")}</>}
                  />
                </div>
              </Col>
            </Row>
            {/* fifth row */}
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvField
                    name="country"
                    type="text"
                    disabled
                    value={profile.country}
                    label={<>{t(" Country of Residence ")}</>}
                  />
                </div>
              </Col>
              <Col md="6">
                {/* <div className="mt-2">
                <AvFieldSelect
                  errorMessage={t("Employment Status is required")}
                  placeholder={t("Select Employment Status")}
                  validate={{ required: { value: true } }}
                  name="experience.employmentStatus"
                  label={t("* Employment Status")}
                  type="text"
                  value={
                    (profile.experience &&
                      profile.experience.employmentStatus) ||
                    ""
                  }
                  onChange={setEmpStatus}
                  options={EMPLOYMENT_STATUS.map((item) => {
                    return {
                      label: `${item.name}`,
                      value: item.value,
                    };
                  })}
                />
              </div> */}
                <div className="mt-2">
                  <AvField
                    name="city"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" City")}
                      </>
                    }
                    placeholder={t("Enter Your City")}
                    type="text"
                    errorMessage={t("City is required")}
                    validate={{ required: { value: true } }}
                    value={profileForm.city}
                  />
                </div>
              </Col>
            </Row>
            {/* sixth row */}
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvField
                    name="address"
                    label={t("Detailed Address (optional)")}
                    placeholder={t("Enter Your address")}
                    type="text"
                    //errorMessage={t("Address is required")}
                    //validate={{ required: { value: true } }}
                    value={profileForm.address}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="experience.profession"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Field of work")}
                      </>
                    }
                    placeholder={t("Select Your Profession")}
                    options={INDUSTRIES?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.experience?.profession}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Profession is required",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            {empStatus === "Employed (full time)" && (
              <Row>
                <Col md="6">
                  <div className="mt-2">
                    <AvField
                      name="experience.jobTitle"
                      label={t("Profession")}
                      placeholder={t("* Enter Job Title")}
                      type="text"
                      errorMessage={t("Job Title is required")}
                      validate={{ required: { value: true } }}
                      value={
                        profileForm.experience &&
                        profileForm.experience.jobTitle
                      }
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mt-2">
                    <AvField
                      name="experience.employer"
                      label={t("Employer")}
                      placeholder={t("Enter Employer Name")}
                      type="text"
                      value={
                        profileForm.experience &&
                        profileForm.experience.employer
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
            {/* seventh row */}
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="financialInfo.sourceOfFunds"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Source Of Fund")}
                      </>
                    }
                    placeholder={t("Source Of Fund")}
                    options={SOURCE_OF_FUNDS?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.financialInfo?.sourceOfFunds}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Source Of Fund is required",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                {/* <div className="mt-2">
                <AvFieldSelect
                  placeholder={t("Select Source Of Funds")}
                  name="financialInfo.sourceOfFunds"
                  label={t("* Source of Funds")}
                  type="text"
                  errorMessage={t("Source Of Funds is required")}
                  validate={{ required: { value: true } }}
                  value={
                    (profile.financialInfo &&
                      profile.financialInfo.sourceOfFunds) ||
                    ""
                  }
                  options={SOURCE_OF_FUNDS.map((item) => {
                    return {
                      label: `${item.name}`,
                      value: item.value,
                    };
                  })}
                />
              </div> */}

                <div className="mt-2">
                  <AvFieldSelect2
                    name="financialInfo.annualIncome"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {t(" Total net worth (cash and bank account balances)")}
                      </>
                    }
                    placeholder={t("Total net worth")}
                    options={ANNUAL_INCOME?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.financialInfo?.annualIncome}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${t("Annual Income is required")}`,
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row> */}
            <Col md="12">
              <div className="mt-2">
                <AvCheckboxGroup inline name="tinNum">
                  <AvCheckbox
                    label="I don't have a TIN"
                    checked={!haveTinNum}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setHaveTinNum(!isChecked);
                      //setTinNum(""); // Clear TIN number if the user doesn't have one
                      setTinNumError(false);
                      setTinNumReason("");
                    }}
                  />
                </AvCheckboxGroup>
              </div>
            </Col>
            {/* </Row> */}
            {/* <Row> */}
            {!haveTinNum && (
              <Col md="12">
                <div className="mt-2">
                  <Select
                    name="tinNumReason"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {props.t(" Please Select a Reason")}
                      </>
                    }
                    options={TinNumReasonOptions}
                    onChange={handleTinNumReasonChange}
                    placeholder={props.t("Choose a Reason")}
                    type="text"
                    errorMessage={props.t("Reason is required")}
                    validate={{ required: { value: true } }}
                    value={tinNumReason}
                  />
                </div>
                {tinNumReasonErrorMessage && (
                  <div className="text-danger">{tinNumReasonErrorMessage}</div>
                )}
              </Col>
            )}

            {tinNumReason.value ==
              "I am unable to obtain a TIN or equivalent (please explain below if this is the case)" && (
              <Col md="12">
                <div className="mt-2">
                  <AvField
                    name="customTinNumReason"
                    label={
                      <>
                        <span style={{ color: "red" }}>*</span>
                        {props.t(" Please Write your Reason")}
                      </>
                    }
                    value={customTinNumReason}
                    onChange={(e) => setCustomTinNumReason(e.target.value)}
                    placeholder={props.t("Write a Reason")}
                    type="text"
                    errorMessage={props.t("Reason is required")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            )}
            {/* </Row> */}
            {/* <Row> */}
            {haveTinNum && (
              <div className="mt-2">
                <AvField
                  name="tinNum"
                  label={
                    <>
                      <span style={{ color: "red" }}>*</span>
                      {t("TIN NUMBER")}
                    </>
                  }
                  placeholder={t("Please TIN Number Enter")}
                  type="text"
                  value={tinNum}
                  disabled={!haveTinNum} // Disable when haveTinNum is false
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (value === "") {
                      setTinNum(value);
                      setTinNumError(false);
                      setTinNumErrorMessage("");
                    } else if (isPositiveNumberOrAlphanumeric(value)) {
                      setTinNum(value);
                      setTinNumError(false);
                      setTinNumErrorMessage("");
                    } else {
                      setTinNumError(true);
                      setTinNumErrorMessage(
                        "TIN Number is either invalid or negative"
                      );
                    }
                  }}
                />
                {tinNumError && (
                  <div className="text-danger">{tinNumErrorMessage}</div>
                )}
              </div>
            )}
            {/* </Row> */}
            <div className="d-flex align-items-center my-2 mt-3 mb-0">
              <div className="flex-grow-1 border-bottom border-secondary"></div>
              <h5
                className="text-center text-secondary mx-3 mb-0"
                style={{ whiteSpace: "nowrap" }}
              >
                Trading Profile
              </h5>
              <div className="flex-grow-1 border-bottom border-secondary"></div>
            </div>
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="tradingProfile.tradingExperience"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t(
                          "Have You Traded Stocks, CFDs or cryptocurrencies before ?"
                        )}
                      </>
                    }
                    placeholder={t("Source Of Fund")}
                    options={TRADING_EXPERIENCE?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.tradingProfile?.tradingExperience}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Trading Experience is required",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2">
                  {/* <AvFieldSelect2
                    name="tradingProfile.tradingGoal"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t("What is Your Primary Goal For Trading With Us ?")}
                      </>
                    }
                    options={TRADING_GOAL?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.tradingProfile?.tradingGoal}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Trading Goal is required",
                      },
                    }}
                  /> */}
                  <AvFieldSelect2
                    name="tradingProfile.tradingGoal"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t("What is Your Primary Goal For Trading With Us ?")}
                      </>
                    }
                    onChange={(e) => {
                      setTradingGoal(e);
                      setProfileData((prevData) => ({
                        ...prevData,
                        tradingProfile: e,
                      }));
                    }}
                    errorMessage={t("Required")}
                    validate={{ required: { value: true } }}
                    value={
                      profileForm?.tradingProfile?.tradingGoal &&
                      (profileForm?.tradingProfile?.tradingGoal ===
                        "Speculative Gains" ||
                      profileForm?.tradingProfile?.tradingGoal ===
                        "Hedging Against Other Investments" ||
                      profileForm?.tradingProfile?.tradingGoal ===
                        "Income Generation" ||
                      profileForm?.tradingProfile?.tradingGoal ===
                        "Diversiffication"
                        ? profileForm?.tradingProfile?.tradingGoal
                        : "Other")
                    }
                    options={TRADING_GOAL.map((item) => {
                      return {
                        label: `${item.name}`,
                        value: item.value,
                      };
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="tradingProfile.depositRange"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t(
                          "What Range of Deposit Amount Do You Expect With Us ?"
                        )}
                      </>
                    }
                    options={DEPOSIT_RANGE?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.tradingProfile?.depositRange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Deposit Range is required",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                {tradingGoal === "Other" && (
                  <div className="mt-2">
                    <AvField
                      name="tradingProfile.tradingGoal"
                      label={
                        <>
                          <span style={{ color: "red" }}>*</span>
                          {props.t(" Other Trading Goal")}
                        </>
                      }
                      onChange={(e) => setOtherTradingGoal(e.target.value)}
                      placeholder={props.t("Enter Your Goal")}
                      type="text"
                      errorMessage={props.t("Trading Goal is required")}
                      validate={{ required: { value: true } }}
                      value={profileForm?.tradingProfile?.tradingGoal}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="tradingProfile.tradingDuration"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t("How Long Do You Plan To Keep Your Trades ?")}
                      </>
                    }
                    options={TRADING_DURATION?.map((item) => ({
                      label: `${item.name}`,
                      value: item.value,
                    }))}
                    value={profileData?.tradingProfile?.tradingDuration}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Trading Duration is required",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mt-2">
                  <AvFieldSelect2
                    name="tradingProfile.leverageAware"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t(
                          "Do You Understand The Risks Involved In Trading Leveraged Products ? "
                        )}
                      </>
                    }
                    options={YESNO}
                    value={profileData?.tradingProfile?.leverageAware}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Leverage Aware is required",
                      },
                    }}
                  />
                  {/* <AvFieldSelect
                    name="tradingProfile.leverageAware"
                    label={
                      <>
                        <span style={{ color: "red" }}>* </span>
                        {t(
                          "Do You Understand The Risks Involved In Trading Leveraged Products ? "
                        )}
                      </>
                    }
                    errorMessage={t("Required")}
                    validate={{ required: { value: true } }}
                    value={profileForm?.tradingProfile?.leverageAware || ""}
                    options={YESNO}
                  /> */}
                </div>
              </Col>
            </Row>
            <Row>
              <h6 className="mb-2">
                {t(
                  "By Clicking Submit, you hereby confirm and agree to the following:"
                )}
              </h6>
              <Col md={12}>
                <AvField
                  name="agreement"
                  label={
                    isClient ? parse(CLIENT_AGREEMENT) : parse(IB_AGREEMENT)
                  }
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked, "checked");
                    if (e.target.checked) {
                      setProfileData((prevData) => ({
                        ...prevData,
                        agreement: e.target.checked,
                      }));
                    } else {
                      setProfileData((prevData) => ({
                        ...prevData,
                        agreement: false,
                      }));
                    }
                  }}
                  value={profileData?.agreement || false}
                  errorMessage={t("Please check the agreement")}
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="regulations"
                  label={t(COUNTRY_REGULATIONS)}
                  type="checkbox"
                  value={profileData?.regulations}
                  errorMessage={t("Please check the agreement")}
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="signature"
                  label={t(E_SIGNATURE)}
                  value={profileData?.signature}
                  type="checkbox"
                  errorMessage={t("Please check the agreement")}
                  validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <div className="p-4">
              <Button
                disabled={submittingProfile}
                type="submit"
                style={{
                  backgroundColor: "#d81618",
                  borderRadius: "10px",
                  padding: "10px 30px",
                  borderColor: "red",
                }}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </AvForm>
      </>
    ),
    2: (
      <AccountRequestForm
        onSubmit={(data) => {
          dispatch(
            createAccountRequest({
              ...data,
            })
          );
          dispatch(
            submitIndProfile({
              ...profileForm,
              submitProfileStartTime: new Date().toISOString(),
            })
          );
          setProfileData(null)
        }}
        back={() => {
          setStage(1);
        }}
        
      />
    ),
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props?.toggle}
        centered={true}
        size={stage === 1 ? "xl" : "md"}
        className="custom-modal"
        style={{
          overflow: "hidden",
          background: "transparent",
        }}
      >
        <ModalHeader className="d-flex flex-column gap-3">
          <CloseButton
            onClick={props?.toggle}
            style={{
              alignSelf: "flex-end",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          />
          <div className="text-center">
            <CardTitle className="mb-0">
              {t(stage === 1 ? "Update Profile" : "Account Request")}
            </CardTitle>
          </div>
        </ModalHeader>
        <CardBody
          style={{
            overflow: "auto",
            padding: 20,
          }}
        >
          {props?.profile?._id && (
            <AvForm
              onValidSubmit={(e, v) => {
                v.declarations = [
                  CLIENT_AGREEMENT,
                  COUNTRY_REGULATIONS,
                  E_SIGNATURE,
                ];
                v.submitProfileStartTime = pageLoadTime;
                delete v.agreement;
                delete v.regulations;
                delete v.signature;
                delete v.tinNum;
                // v.tinNum = Number(parseInt(v.tinNum, 10));
                dispatch(submitIndProfile(v));
                props.toggle();
              }}
            >
              <div className="d-flex flex-column gap-2">
                {/* first row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="title"
                        type="text"
                        placeholder={props.t("Select Your Title")}
                        errorMessage={props.t("Title is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.title || ""}
                        label={props.t("Title")}
                        options={TITLES.map((obj) => {
                          return ({
                            label: obj.name,
                            value: obj.value
                          });
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvFieldSelect
                        name="language"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Language")}
                          </>
                        }
                        placeholder={props.t("Select Your Language")}
                        type="text"
                        errorMessage={props.t("Language is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.language || ""}
                        options={LANGUAGES.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mt-2 ">
                      <AvField
                        name="email"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Email Address")}
                          </>
                        }
                        // placeholder={props.t("Enter Address Line 2")}
                        type="text"
                        // errorMessage={props.t("Address is required")}
                        // validate={{ required: { value: true } }}
                        defaultValue={props.profile.email}
                        disabled
                      />
                    </div>
                  </Col>
                  {/* <Col md="5">
                    <div className="mt-2">
                      <AvFieldSelect
                        name="nationality"
                        label={props.t("* Nationality")}
                        errorMessage={props.t("Nationality is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.nationality || ""}
                        placeholder={props.t("Select Your Nationality")}
                        options={props.countries.map((country) => {
                          return {
                            label: `${country.en}`,
                            value: country.en,
                          };
                        })}
                      />
                    </div>
                  </Col> */}
                </Row>
                {/* second row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvField
                        name="address"
                        label={props.t("* Address")}
                        placeholder={props.t("Enter Your address")}
                        type="text"
                        errorMessage={props.t("Address is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.address}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvField
                        name="phone"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Mobile Phone Number")}
                          </>
                        }
                        placeholder={props.t("Enter Postal Code")}
                        type="text"
                        value={props.profile.phone}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mt-2">
                      <AvFieldSelect
                        name="gender"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Gender")}
                          </>
                        }
                        placeholder={props.t("Select Gender")}
                        type="date"
                        errorMessage={props.t("Gender is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.gender || ""}
                        options={GENDER}
                      />
                    </div>
                  </Col>
                </Row>
                {/* third row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2 ">
                      <AvField
                        name="email"
                        label={props.t("* Email")}
                        // placeholder={props.t("Enter Address Line 2")}
                        type="text"
                        // errorMessage={props.t("Address is required")}
                        // validate={{ required: { value: true } }}
                        defaultValue={props.profile.email}
                        disabled
                      />
                    </div> */}
                    <div className="mt-2 ">
                      {/* <AvField
                        name="fullName"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Full Name")}
                          </>
                        }
                        // placeholder={props.t("Enter Address Line 2")}
                        type="text"
                        // errorMessage={props.t("Address is required")}
                        // validate={{ required: { value: true } }}
                        defaultValue={
                          props.profile.firstName + " " + props.profile.lastName
                        }
                        disabled
                      /> */}
                      <label>
                        <span style={{ color: "red" }}>*</span>
                        {props.t(" Full Name")}
                      </label>
                      <div
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          padding: ".375rem .75rem",
                          height: "calc(1.5em + .75rem + 2px)",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#e9ecef",
                          color: "#495057",
                          cursor: "not-allowed",
                        }}
                      >
                        {props.profile.firstName + " " + props.profile.lastName}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="language"
                        label={props.t("* Language")}
                        placeholder={props.t("Select Your Language")}
                        type="text"
                        errorMessage={props.t("Language is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.language || ""}
                        options={LANGUAGES.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvField
                        name="dob"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Birthday")}
                          </>
                        }
                        placeholder={props.t("Enter Date of birth")}
                        type="date"
                        min={moment()
                          .subtract("110", "years")
                          .format("YYYY-MM-DD")}
                        max={moment()
                          .subtract("18", "years")
                          .format("YYYY-MM-DD")}
                        errorMessage={props.t("DOB is required")}
                        validate={{
                          required: { value: true },
                          dateRange: {
                            format: "YYYY-MM-DD",
                            start: {
                              value: moment()
                                .subtract("110", "years")
                                .format("YYYY-MM-DD"),
                            },
                            end: {
                              value: moment()
                                .subtract("18", "years")
                                .format("YYYY-MM-DD"),
                            },
                          },
                        }}
                        value={props.profile.dob}
                      />
                    </div>
                  </Col>
                </Row>
                {/* fourth row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvField
                        name="phone"
                        label={props.t("Phone Number")}
                        placeholder={props.t("Enter Postal Code")}
                        type="text"
                        value={props.profile.phone}
                        disabled
                      />
                    </div> */}
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="documentType"
                        label={props.t("Document Type ")}
                        // errorMessage={props.t("country is required")}
                        // validate={{ required: { value: true } }}
                        value={props.profile.documentType || ""}
                        placeholder={props.t("Document Number (optional)")}
                        options={DOC.map((i) => {
                          return {
                            label: i,
                            value: i,
                          };
                        })}
                      />
                    </div> */}
                  </Col>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvField
                        name="city"
                        label={props.t("* City")}
                        placeholder={props.t("Enter Your City")}
                        type="text"
                        errorMessage={props.t("City is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.city}
                      />
                    </div> */}
                    {/* <div className="mt-2">
                      <AvField
                        name="documentNum"
                        label={props.t("Document Number (optional)")}
                        placeholder={props.t("Enter Document Number")}
                        type="number"
                        value={props.profile.documentType}
                      />
                    </div> */}
                  </Col>
                </Row>
                {/* fifth row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="experience.profession"
                        label={props.t("* Field of work")}
                        placeholder={props.t("Select Your Profession")}
                        type="text"
                        errorMessage={props.t("Profession is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.experience &&
                            props.profile.experience.profession) ||
                          ""
                        }
                        options={INDUSTRIES.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvFieldSelect
                        name="country"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Country of Residence ")}
                          </>
                        }
                        errorMessage={props.t("country is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.country || ""}
                        placeholder={props.t("Select Your country")}
                        options={props.countries.map((country) => {
                          return {
                            label: `${country.en}`,
                            value: country.en,
                          };
                        })}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        errorMessage={props.t("Employment Status is required")}
                        placeholder={props.t("Select Employment Status")}
                        validate={{ required: { value: true } }}
                        name="experience.employmentStatus"
                        label={props.t("* Employment Status")}
                        type="text"
                        value={
                          (props.profile.experience &&
                            props.profile.experience.employmentStatus) ||
                          ""
                        }
                        onChange={setEmpStatus}
                        options={EMPLOYMENT_STATUS.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvField
                        name="city"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" City")}
                          </>
                        }
                        placeholder={props.t("Enter Your City")}
                        type="text"
                        errorMessage={props.t("City is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.city}
                      />
                    </div>
                  </Col>
                </Row>
                {/* sixth row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="documentType"
                        label={props.t("* Document Type ")}
                        // errorMessage={props.t("country is required")}
                        // validate={{ required: { value: true } }}
                        value={props.profile.documentType || ""}
                        placeholder={props.t("Select Your Document (optional)")}
                        options={DOC.map((i) => {
                          return {
                            label: i,
                            value: i,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvField
                        name="address"
                        label={props.t("Detailed Address (optional)")}
                        placeholder={props.t("Enter Your address")}
                        type="text"
                        //errorMessage={props.t("Address is required")}
                        //validate={{ required: { value: true } }}
                        value={props.profile.address}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvField
                        name="documentNum"
                        label={props.t("Document Number (optional)")}
                        placeholder={props.t("Enter Document Number")}
                        type="number"
                        value={props.profile.documentType}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvFieldSelect
                        name="experience.profession"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Field of work")}
                          </>
                        }
                        placeholder={props.t("Select Your Profession")}
                        type="text"
                        errorMessage={props.t("Profession is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.experience &&
                            props.profile.experience.profession) ||
                          ""
                        }
                        options={INDUSTRIES.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div>
                  </Col>
                </Row>
                {empStatus === "Employed (full time)" && (
                  <Row>
                    <Col md="6">
                      <div className="mt-2">
                        <AvField
                          name="experience.jobTitle"
                          label={props.t("Profession")}
                          placeholder={props.t("* Enter Job Title")}
                          type="text"
                          errorMessage={props.t("Job Title is required")}
                          validate={{ required: { value: true } }}
                          value={
                            props.profile.experience &&
                            props.profile.experience.jobTitle
                          }
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mt-2">
                        <AvField
                          name="experience.employer"
                          label={props.t("Employer")}
                          placeholder={props.t("Enter Employer Name")}
                          type="text"
                          value={
                            props.profile.experience &&
                            props.profile.experience.employer
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {/* seventh row */}
                <Row>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        placeholder={props.t("Select Annual Income")}
                        name="financialInfo.annualIncome"
                        label={props.t(
                          "* Total net worth (cash and bank account balances)"
                        )}
                        type="text"
                        errorMessage={props.t("Annual Income is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.financialInfo &&
                            props.profile.financialInfo.annualIncome) ||
                          ""
                        }
                        options={ANNUAL_INCOME.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvFieldSelect
                        placeholder={props.t("Source Of Fund")}
                        name="financialInfo.sourceOfFunds"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(" Source Of Fund")}
                          </>
                        }
                        type="text"
                        errorMessage={props.t("Source Of Funds is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.financialInfo &&
                            props.profile.financialInfo.sourceOfFunds) ||
                          ""
                        }
                        options={SOURCE_OF_FUNDS.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        placeholder={props.t("Select Source Of Funds")}
                        name="financialInfo.sourceOfFunds"
                        label={props.t("* Source of Funds")}
                        type="text"
                        errorMessage={props.t("Source Of Funds is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.financialInfo &&
                            props.profile.financialInfo.sourceOfFunds) ||
                          ""
                        }
                        options={SOURCE_OF_FUNDS.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div> */}
                    <div className="mt-2">
                      <AvFieldSelect
                        placeholder={props.t("Total net worth")}
                        name="financialInfo.annualIncome"
                        label={
                          <>
                            <span style={{ color: "red" }}>*</span>
                            {props.t(
                              " Total net worth (cash and bank account balances)"
                            )}
                          </>
                        }
                        type="text"
                        errorMessage={props.t("Annual Income is required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.financialInfo &&
                            props.profile.financialInfo.annualIncome) ||
                          ""
                        }
                        options={ANNUAL_INCOME.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: item.value,
                          };
                        })}
                      />
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="6">
                    <div className="mt-2">
                      <AvFieldSelect
                        name="politicallyExposed"
                        placeholder={props.t(
                          "Select are you Politically Exposed"
                        )}
                        label={props.t("* Politically Exposed ?")}
                        type="text"
                        errorMessage={props.t("Required")}
                        validate={{ required: true }}
                        value={props.profile.politicallyExposed || ""}
                        options={YESNO}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mt-2">
                      <AvFieldSelect
                        name="usCitizen"
                        label={props.t(
                          "* Are you a citizen of the United States of America ?"
                        )}
                        placeholder={props.t("Select are you US Citizen")}
                        type="text"
                        errorMessage={props.t("Required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.usCitizen}
                        options={YESNO}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="mt-2">
                      <AvFieldSelect
                        name="financialInfo.workedInFinancial"
                        label={props.t(
                          "* I have worked for at least 2 years in a directly relevant role within the financial services industry ?"
                        )}
                        placeholder={props.t(
                          "Select have you worked in finance"
                        )}
                        type="text"
                        errorMessage={props.t("Required")}
                        validate={{ required: { value: true } }}
                        value={
                          (props.profile.financialInfo &&
                            props.profile.financialInfo.workedInFinancial) ||
                          ""
                        }
                        options={YESNO}
                      />
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="12">
                    {/* <div className="mt-2">
                      <AvFieldSelect
                        name="country"
                        label={props.t("* Country of Residence ")}
                        errorMessage={props.t("country is required")}
                        validate={{ required: { value: true } }}
                        value={props.profile.country || ""}
                        placeholder={props.t("Select Your country")}
                        options={props.countries.map((country) => {
                          return {
                            label: `${country.en}`,
                            value: country.en,
                          };
                        })}
                      />
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="12">
                    <div className="mt-2">
                      <AvCheckboxGroup inline name="tinNum">
                        <AvCheckbox
                          label="I don't have a TIN"
                          checked={!haveTinNum}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setHaveTinNum(!isChecked); // Update haveTinNum based on checkbox state

                            if (isChecked) {
                              // If checkbox is checked (user doesn't have TIN), clear tinNum and error
                              setTinNum("");
                              setTinNumError(false);
                            }
                          }}
                          onClick={(e) => {
                            const isChecked = e.target.checked;
                            setHaveTinNum(!isChecked); // Update haveTinNum based on checkbox state

                            if (isChecked) {
                              // If checkbox is checked (user doesn't have TIN), clear tinNum and error
                              setTinNum("");
                              setTinNumError(false);
                            }
                          }}
                        />
                      </AvCheckboxGroup>
                    </div>
                  </Col> */}
                  {/* <Col md="12">
                    {true && (
                      <div className="mt-2">
                        <AvField
                          name="tinNumMissReason"
                          label={
                            <>
                              <span style={{ color: "red" }}>*</span>
                              {props.t(" Reason for not having TIN")}
                            </>
                          }
                          onChange={(e) => {
                            setTinNumReason(e.target.value);
                          }}
                          placeholder={props.t("Enter Your Reason")}
                          type="text"
                          errorMessage={props.t("Reason is required")}
                          validate={{ required: { value: true } }}
                          value={props.profile.tinNumMissReason}
                        />
                      </div>
                    )}
                  </Col> */}
                </Row>
                <Row>
                  <h6 className="mb-2">
                    {props.t(
                      "By Clicking Submit, you hereby confirm and agree to the following:"
                    )}
                  </h6>
                  <Col md={12}>
                    <AvField
                      name="agreement"
                      label={
                        props.isClient
                          ? parse(CLIENT_AGREEMENT)
                          : parse(IB_AGREEMENT)
                      }
                      
                      type="checkbox"
                      errorMessage={props.t("Please check the agreement")}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="regulations"
                      label={props.t(COUNTRY_REGULATIONS)}
                      type="checkbox"
                      errorMessage={props.t("Please check the agreement")}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="signature"
                      label={props.t(E_SIGNATURE)}
                      type="checkbox"
                      errorMessage={props.t("Please check the agreement")}
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                </Row>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="p-4">
                  <Button
                    disabled={props.submittingProfile}
                    type="submit"
                    style={{
                      backgroundColor: "#d81618",
                      borderRadius: "10px",
                      padding: "10px 30px",
                      borderColor: "red",
                    }}
                  >
                    {props.t("Continue")}
                  </Button>
                </div>
              </div>
            </AvForm>
          )}
          {profile._id && form[stage]}
        </CardBody>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(SubmitProfile);