import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { addFxWithdraw } from "../../../apis/withdraw";
import calculateFee from "../../../helpers/calculateTranFee";
import CardWrapper from "../../Common/CardWrapper";
import CustomModal from "../../Common/CustomModal";
import AddBankAccountModal from "../../BankAccounts/AddBankAccountModal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { getAccountsStart } from "store/actions";
import { withdrawalConfig } from "./withdrawalConfig";
import { validateFile } from "helpers/validations/file";
import AsiaBanks from "components/Deposit/Methods/AsiaBanks";
import CustomSelect from "components/Common/CustomSelect";
import AddUsdtMethodModal from "components/UsdtMethod/AddUsdtMethodModal";
import { isString } from "lodash";
import { PerfectMoney } from "components/Deposit/Methods/PerfectMoney";
import PerfectMoneyWithdrawal from "components/Deposit/Methods/PerfectMoney/PerfectMoneyWithdrawal";
import { getConversionRate } from "apis/conversionRate";

function Mt5Withdraw({ isOpen, toggleOpen, ...props }) {
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const dispatch = useDispatch();
  const tranFeeGroupDetails = useSelector(
    (state) => state.Profile?.clientData?.transactionFeeId
  );
  const { accounts } = useSelector((state) => state.forex.accounts);
  const { clientData } = useSelector((state) => state.Profile);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [selectedWallet, setSelectedWallet] = useState("USD");
  const [amount, setAmount] = useState("");
  const [gateway, setGateway] = useState("");
  const [transactionFee, setTransactionFee] = useState("");
  const [result, setResult] = useState("");
  const [selectGatwayError, setSelectGatwayError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [amountValidation, setAmountValidation] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectWalletError, setSelectWalletError] = useState(false);
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [addBankModal, setAddBankModal] = useState(false);
  const [bankAccount, setBankAccount] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(accounts?.[0]);
  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [addUsdtModal, setAddUsdtModal] = useState(false);
  const [targetCurrency, setTargetCurrency] = useState(""); // For PerfectMoney
  const [perfectMoneyId, setPerfectMoneyId] = useState("");
  const [conversionRate, setConversionRate] = useState(0);

  const bankAccounts = useSelector(
    (state) => state.crypto.bankAccounts?.bankAccounts
  );

  const [selectedAccountId, setSelectedAccountId] = useState("");

  const [methodConfig, setMethodConfig] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    getConversionRate({
      baseCurrency,
      targetCurrency,
    }).then((res) => {
      setConversionRate(res);
    });
    console.log("Executed base-target currency", baseCurrency, targetCurrency);
    
  }, [baseCurrency, targetCurrency]);

  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      // setSelectedWallet(defaultFiatWallet?.value);
      setGateway("");
      setAmount("");
      setIsFirstStepValid(false);
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(
      getAccountsStart({
        limit: 100,
      })
    );
  }, []);

  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }
  useEffect(() => {
    setTransactionFee(
      tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0
    );
  }, [tranFeeGroupDetails, amount]);

  const handleSubmit = (e, v) => {
    console.log("values", v);
    setLoading(true);
    const formData = new FormData();
    formData.append("gateway", gateway);
    if (gateway === "PERFECT_MONEY") {
      formData.append("walletId", perfectMoneyId);
    }
    formData.append("amount", amount);
    formData.append("tradingAccountId", selectedAccount);
    formData.append("note", "note");
    formData.append("payload", JSON.stringify(methodConfig));
    if (methodConfig?.file) {
      formData.append("receipt", methodConfig?.file);
    }
    
    addFxWithdraw({
      gateway,
      amount,
      tradingAccountId: selectedAccount,
      note: "NOTE",
      payload: `${JSON.stringify(methodConfig)}`,
    })
      .then((e) => {
        // console.log(e);
        setLoading(false);
        setResult(e);
        toggleTab(2);
      })
      .catch((e) => {
        setLoading(false);
        // console.log(e);
        setResult(e);
        toggleTab(2);
      });
    // toggleOpen()
  };

  const validateStep1 = () => {
    if (selectedWallet === "") {
      setSelectWalletError(true);
      setTimeout(() => {
        setSelectWalletError(false);
      }, 2000);
    }
    if (gateway === "") {
      setSelectGatwayError(true);
      setTimeout(() => {
        setSelectGatwayError(false);
      }, 2000);
    }
    if (gateway != "") {
      toggleTab(1);
    }
  };
  const validateStep2 = (e, v) => {
    if (amount == "") {
      setAmountError(true);
      setTimeout(() => {
        setAmountError(false);
      }, 2000);
    } else {
      handleSubmit(e, v);
    }
  };

  const amountValidationHanlder = (e) => {
    e.target.value < 30
      ? setAmountValidation(true)
      : setAmountValidation(false);
  };

  const isFirstSubmitValid = () => {
    if (gateway === "") {
      return setIsFirstStepValid(false);
    } else if (
      gateway === "WIRE_TRANSFER" ||
      gateway === "LOCAL_WIRE_TRANSFER"
    ) {
      if (Object.keys(bankAccount).length === 0 || !isString(selectedAccount)) {
        return setIsFirstStepValid(false);
      } else {
        return setIsFirstStepValid(true);
      }
    } else if (gateway === "PERFECT_MONEY") {
      if (isString(selectedAccount) && perfectMoneyId !== "") {

        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      }
    }
    else if (gateway === "CRYPTO") {
      if (
        (bankAccount?.network &&
        bankAccount?.address &&
        bankAccount.currency === "USD") && isString(selectedAccount)
      ) {
        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      } 
    } else if (gateway === "صناديق-الاعتماد") {
      if (methodConfig.type === "حواله") {
        if (
          methodConfig.file &&
          methodConfig.locationOfHawala !== "" &&
          methodConfig.locationOfHawala !== null &&
          methodConfig.locationOfHawala !== undefined
        ) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
      if (methodConfig.type === "اعتماد") {
        if (
          methodConfig.nameOfBox !== "" &&
          methodConfig.numberOfBox !== "" &&
          methodConfig.nameOfBox !== undefined &&
          methodConfig.numberOfBox !== undefined
        ) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
    } else if (gateway === "USDT") {
      if (
        methodConfig?.bankAccount?.network &&
        methodConfig?.bankAccount?.address &&
        methodConfig?.bankAccount?.currency === "USD"
      ) {
        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      }
    }
    return setIsFirstStepValid(false);
  };

  useEffect(() => {
    isFirstSubmitValid();
  }, [gateway, bankAccount, bankAccounts, methodConfig, selectedAccount]);

  useEffect(() => {
    setIsFirstStepValid(false);
  }, [gateway]);

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div className="my-4">
            <AvForm onValidSubmit={validateStep1}>
              <div className="mb-4">
                <h6 className="mb-3">
                  {props.t("Select Payment Method")}
                  {selectGatwayError && (
                    <p className="small text-danger ">
                      {props.t("Please Select Payment Method")}
                    </p>
                  )}
                </h6>
                <Row className="justify-content-center payment-methods">
                  {withdrawalConfig
                    .filter((method) => method.allowed.mt5)
                    .map((method, index) => (
                      <Col xs={4} lg={2} className="my-2" key={index}>
                        <button
                          type="button"
                          onClick={() => {
                            setGateway(method.gateway);
                            setSelectedPaymentMethod(method.gateway);
                            setMethodConfig(method);
                          }}
                          onMouseEnter={() => {
                            setHoveredPaymentMethod(method.gateway);
                          }}
                          onMouseLeave={() => {
                            setHoveredPaymentMethod();
                          }}
                          className={`btn btn-${
                            selectedPaymentMethod === method.gateway
                              ? "success"
                              : hoveredPaymentMethod === method.gateway
                                ? "default"
                                : "light"
                          } waves-effect waves-light w-sm py-4`}
                          style={{
                            maxWidth: "80px",
                            maxHeight: "80px",
                          }}
                        >
                          <img
                            src={method.image}
                            // width="100%"
                            // height="100%"
                            alt=""
                          ></img>
                        </button>
                      </Col>
                    ))}
                </Row>
              </div>
              {gateway == "WIRE_TRANSFER" && (
                <div className="mb-3">
                  <div>
                    <Label>{props.t("Select Bank Account")}</Label>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-2">
                        <AvFieldSelecvt
                          name="bankAccount"
                          className="form-select"
                          value={bankAccount}
                          onChange={(e) => {
                            setBankAccount(e);
                            setMethodConfig({
                              ...methodConfig,
                              bankAccount: e,
                              type: "BANK",
                            });
                            setSelectedAccountId(e?._id);
                            setTargetCurrency(e?.currency);
                            // console.log("Base Currency", e?.currency);
                          }}
                          required
                          placeholder="Select Bank Account"
                          options={bankAccounts
                            ?.filter(
                              (bankAccount) => bankAccount.type === "BANK"
                            )
                            ?.map((bankAccount) => ({
                              label: `${bankAccount.bankName} - ${bankAccount.accountNumber}`,
                              value: bankAccount,
                            }))}
                        />
                      </div>
                      <div>
                        <InputGroup>
                          <InputGroupText className="custom-input-group-text mt-4">
                            <Link
                              to="#"
                              onClick={() => {
                                setAddBankModal(true);
                              }}
                            >
                              {props.t("Add New")}
                            </Link>
                          </InputGroupText>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-5">
                    <div className="w-50">
                      {bankAccount && Object.keys(bankAccount).length > 0 && (
                        <div>
                          {bankAccount.type === "BANK" && (
                            <AvForm>
                              <h5 className="mb-1 mt-3">
                                {props.t("Payment details")}
                              </h5>
                              <div>
                                <Label>{props.t("Beneficiary Name")}</Label>
                                <AvField
                                  type="text"
                                  name="accountHolderName"
                                  value={`${clientData?.firstName} ${clientData?.lastName}`}
                                  validate={{ required: true }}
                                  disabled={true}
                                ></AvField>
                              </div>
                              <div>
                                <Label>{props.t("Bank Name")}</Label>
                                <AvField
                                  type="text"
                                  name="bankName"
                                  value={bankAccount.bankName}
                                  validate={{ required: true }}
                                  disabled={true}
                                ></AvField>
                              </div>
                              <div>
                                <Label>{props.t("Account Number")}</Label>
                                <AvField
                                  type="text"
                                  name="accountNumber"
                                  value={bankAccount.accountNumber}
                                  validate={{ required: true }}
                                  disabled={true}
                                ></AvField>
                              </div>
                              <div>
                                <Label>{props.t("Address")}</Label>
                                <AvField
                                  type="text"
                                  name="address"
                                  value={bankAccount.address}
                                  validate={{ required: true }}
                                  disabled={true}
                                ></AvField>
                              </div>
                              <div>
                                <Label>{props.t("Swift/IFSC Code")}</Label>
                                <AvField
                                  type="text"
                                  name="swiftCode"
                                  value={bankAccount.swiftCode}
                                  validate={{ required: true }}
                                  disabled={true}
                                ></AvField>
                              </div>
                              <div>
                                <Label>{props.t("Currency")}</Label>
                                <AvField
                                  type="text"
                                  name="currency"
                                  disabled={true}
                                  value={bankAccount.currency}
                                  validate={{ required: true }}
                                ></AvField>
                              </div>
                              <p>{props.t("Bank Account")}</p>
                              {/* <p className="text-muted">
                              {props.t("You MUST include the Reference Code in your deposit in order to credit your account!")}
                            </p> */}
                            </AvForm>
                          )}
                        </div>
                      )}
                    </div>
                    {bankAccount && Object.keys(bankAccount).length > 0 && (
                      <div className="mb-3 w-50">
                        <div className="mb-2">
                          <h5 className="mb-1 mt-3">
                            {"Remittance Payment Instructions"}
                          </h5>
                          <p className="text-secondary">
                            {
                              "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                            }
                          </p>
                        </div>
                        <div className="mb-2">
                          <h5 className="my-1">{"Withdrawal Rules"}</h5>
                          <ul>
                            <li className="text-secondary">
                              {"1- The minimum withdrawal amount is 30 USD."}
                            </li>
                            <li className="text-secondary">
                              {
                                "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                              }
                            </li>
                            <li className="text-secondary">
                              {
                                "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                              }
                            </li>
                            <li className="text-secondary">
                              {
                                "4- Withdrawals made via Visa or MasterCard typically take 5 to 14 days for processing, and please be advised that a 1% fee will be applied to these transactions."
                              }
                            </li>
                          </ul>
                        </div>
                        <div className="mb-2">
                          <h5 className="my-1">{"Important Information:"}</h5>
                          <p className="text-secondary">
                            {
                              "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                            }
                          </p>
                          <p className="text-secondary">
                            {
                              "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                            }
                          </p>
                          <p className="text-secondary">
                            {
                              "The withdrawal currency must match the deposit currency."
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {gateway === "PERFECT_MONEY" && (
                <div className="d-flex justify-content-between align-items-start">
                  <div className="w-50">
                    <PerfectMoneyWithdrawal
                      t={t}
                      //setIsFirstStepValid={setIsFirstStepValid}
                      methodConfig={methodConfig}
                      setMethodConfig={setMethodConfig}
                      perfectMoneyId={perfectMoneyId}
                      setPerfectMoneyId={setPerfectMoneyId}
                      targetCurrency={targetCurrency}
                      setTargetCurrency={setTargetCurrency}
                    />
                  </div>
                  <div className="mb-3 w-50 ms-5 flex-1">
                    <div className="mb-2">
                      <h5 className="my-1">
                        {"Digital Currency Withdrawal Instructions"}
                      </h5>
                      <p className="text-secondary">
                        {
                          "For any initial USDT withdrawal request or if you're using a new USDT address, you'll soon receive a confirmation email from "
                        }
                        <a
                          href="mailto:withdrawal@mhmarkets.com"
                          style={{ fontWeight: "bold" }}
                        >
                          {"withdrawal@mhmarkets.com"}
                        </a>
                        {
                          " to verify the transaction. Kindly respond to this email at your earliest convenience so that we can proceed with the processing of your withdrawal request."
                        }
                      </p>
                      <p className="text-secondary">
                        {
                          "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                        }
                      </p>
                    </div>
                    <div className="mb-2">
                      <h5 className="my-1">{"Withdrawal Rules:"}</h5>
                      <ul>
                        <li>{"1- The minimum withdrawal amount is 30 USD."}</li>
                        <li>
                          {
                            "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                          }
                        </li>
                        <li>
                          {
                            "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                          }
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h5 className="my-1">{"Important Information:"}</h5>
                      <p className="text-secondary">
                        {
                          "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                        }
                      </p>
                      <p className="text-secondary">
                        {
                          "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                        }
                      </p>
                      <p className="text-secondary">
                        {
                          "The withdrawal currency must match the deposit currency."
                        }
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {gateway === "ASIA_BANKS" && (
                <AsiaBanks
                  t={t}
                  setIsFirstStepValid={setIsFirstStepValid}
                  setBaseCurrency={setBaseCurrency}
                />
              )}
              {gateway === "CRYPTO" && (
                <div>
                  <div className="mb-3">
                    <Label>{t("Select USDT Account")}</Label>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-2">
                        <AvFieldSelecvt
                          name="cryptoAccount"
                          className="form-select"
                          onChange={(e) => {
                            setBankAccount(e); // Update bankAccount state
                            setMethodConfig({
                              ...methodConfig,
                              bankAccount: e,
                              type: "USDT",
                            });
                          }}
                          required
                          placeholder={t("Select USDT Account")}
                          options={bankAccounts
                            ?.filter(
                              (bankAccount) => bankAccount.type === "USDT"
                            )
                            ?.map((bankAccount) => ({
                              label: `${bankAccount.network} - ${bankAccount.address}`,
                              value: bankAccount,
                            }))}
                        />
                      </div>
                      <div>
                        <InputGroup>
                          <InputGroupText className="custom-input-group-text mt-4">
                            <Link
                              to="#"
                              onClick={() => {
                                setAddUsdtModal(true);
                              }}
                            >
                              {props.t("Add New")}
                            </Link>
                          </InputGroupText>
                        </InputGroup>
                      </div>
                    </div>
                    {bankAccounts?.filter(
                      (bankAccount) => bankAccount.type === "USDT"
                    ).length === 0 && (
                      <p className="text-danger mt-2">
                        {t(
                          "No USDT accounts available. Please add a USDT account."
                        )}
                      </p>
                    )}
                  </div>
                  <div className="d-flex gap-5">
                    <div className="w-50">
                      {/* Display USDT account details when selected */}
                      {methodConfig.bankAccount &&
                        methodConfig.bankAccount.type === "USDT" && (
                        <div className="mt-3">
                          <AvForm>
                            <h5 className="mb-4">
                              {props.t("USDT Account Details")}
                            </h5>
                            <div>
                              <Label>{props.t("Network")}</Label>
                              <AvField
                                type="text"
                                name="cryptoNetwork"
                                value={methodConfig.bankAccount.network}
                                validate={{ required: true }}
                                disabled={true}
                              ></AvField>
                            </div>
                            <div>
                              <Label>{props.t("Address")}</Label>
                              <AvField
                                type="text"
                                name="cryptoAddress"
                                value={methodConfig.bankAccount.address}
                                validate={{ required: true }}
                                disabled={true}
                              ></AvField>
                            </div>
                            <p>{props.t("USDT Account")}</p>
                          </AvForm>
                        </div>
                      )}
                    </div>
                    {methodConfig.bankAccount &&
                      methodConfig.bankAccount.type === "USDT" && (
                      <div className="mb-3 w-50">
                        <div className="mb-2">
                          <h5 className="my-1">
                            {"Digital Currency Withdrawal Instructions"}
                          </h5>
                          <p className="text-secondary">
                            {
                              "For any initial USDT withdrawal request or if you're using a new USDT address, you'll soon receive a confirmation email from "
                            }
                            <a
                              href="mailto:withdrawal@mhmarkets.com"
                              style={{ fontWeight: "bold" }}
                            >
                              {"withdrawal@mhmarkets.com"}
                            </a>
                            {
                              " to verify the transaction. Kindly respond to this email at your earliest convenience so that we can proceed with the processing of your withdrawal request."
                            }
                          </p>
                          <p className="text-secondary">
                            {
                              "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                            }
                          </p>
                        </div>
                        <div className="mb-2">
                          <h5 className="my-1">{"Withdrawal Rules:"}</h5>
                          <ul>
                            <li className="text-secondary">
                              {"1- The minimum withdrawal amount is 30 USD."}
                            </li>
                            <li className="text-secondary">
                              {
                                "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                              }
                            </li>
                            <li className="text-secondary">
                              {
                                "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                              }
                            </li>
                          </ul>
                        </div>
                        <div>
                          <h5 className="my-1">{"Important Information:"}</h5>
                          <p className="text-secondary">
                            {
                              "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                            }
                          </p>
                          <p className="text-secondary">
                            {
                              "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                            }
                          </p>
                          <p className="text-secondary">
                            {
                              "The withdrawal currency must match the deposit currency."
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* {gateway === "PERFECT_MONEY" && (
                <>
                  <PerfectMoney />
                </>
              )} */}
              {gateway === "صناديق-الاعتماد" && (
                <>
                  <div className="mb-3">
                    <Label>{t("Type")}</Label>
                    <AvFieldSelecvt
                      name="sandoqType"
                      className="form-select"
                      placeholder="Select Type"
                      onChange={(e) => {
                        setMethodConfig({
                          ...methodConfig,
                          type: e,
                        });
                      }}
                      options={methodConfig?.config?.methods?.map((option) => {
                        return {
                          label: option?.title,
                          value: option?.title,
                        };
                      })}
                    />
                  </div>
                  {methodConfig?.type && (
                    <>
                      {methodConfig.type === "حواله" && (
                        <>
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="receipt">
                              {t("Receipt")}
                            </Label>
                            <AvField
                              type="file"
                              name="receipt"
                              className="form-control form-control-md"
                              errorMessage={t("Receipt is required")}
                              onChange={(e) => {
                                setMethodConfig({
                                  ...methodConfig,
                                  file: e.target.files[0],
                                });
                              }}
                              validate={{
                                required: { value: true },
                                validate: validateFile(
                                  ["jpg", "jpeg", "png"],
                                  1000000,
                                  methodConfig?.file || {},
                                  {
                                    sizeValidationMessage: t(
                                      "The file size is too large"
                                    ),
                                    extensionValidationMessage: t(
                                      "The file extension is not allowed"
                                    ),
                                  }
                                ),
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>{t("وجه الحواله")}</Label>
                            <AvField
                              name="locationOfHawala"
                              type="text"
                              placeholder="وجه الحواله"
                              onChange={(e) => {
                                setMethodConfig({
                                  ...methodConfig,
                                  locationOfHawala: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                      {methodConfig.type === "اعتماد" && (
                        <>
                          <div className="mb-3">
                            <Label>{t("اسم الصندوق")}</Label>
                            <AvField
                              name="nameOfBox"
                              type="text"
                              placeholder="اسم الصندوق"
                              onChange={(e) => {
                                setMethodConfig({
                                  ...methodConfig,
                                  nameOfBox: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>{t("رقم الصندوق")}</Label>
                            <AvField
                              name="numberOfBox"
                              type="text"
                              placeholder="رقم الصندوق"
                              onChange={(e) => {
                                setMethodConfig({
                                  ...methodConfig,
                                  numberOfBox: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {gateway == "SKRILL" ||
              gateway == "NETELLER" ||
              gateway == "MASTERCARD" ? (
                  <>
                    <p className="text-muted">
                      {props.t("")}
                      {props.t("Enter card information.")}
                    </p>
                    <Row>
                      <AvForm>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              {props.t("Name")}
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              id="example-date-input"
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              {props.t("Card Number")}
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              id="example-date-input"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              {props.t("Expiry date")}
                            </Label>
                            <Input
                              className="form-control"
                              type="date"
                              id="example-date-input"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              {props.t("Security Code (CVV)")}
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              id="example-date-input"
                            />
                          </div>
                        </Col>
                      </AvForm>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              {gateway && (
                <div className="mt-2">
                  <label
                    className="font-weight-bold"
                    style={{
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    Select Trading Account
                  </label>
                  <div className="mt-3">
                    <CustomSelect
                      placeholder="Select Trading Account"
                      options={accounts
                        ?.filter((a) => !["DEMO", "IB"].includes(a.type))
                        ?.filter((a) =>
                          a.profile ? a.profile?.profileType === 9 : true
                        )
                        ?.filter((a) => !a.withdrawalDisabled)
                        // ?.filter((a) => {
                        //   if (gateway === "CRYPTO") {
                        //     return a.accountTypeId?.title === "USDT";
                        //   } else if (
                        //     gateway === "WIRE_TRANSFER" ||
                        //     gateway === "LOCAL_WIRE_TRANSFER"
                        //   ) {
                        //     return a.accountTypeId?.title === "BANK";
                        //   }
                        //   return true; // For other gateways, don't filter by account type
                        // })
                        ?.map((acc) => {
                          return {
                            label: `${acc?.login} ${acc?.accountTypeId?.title} (${acc?.Balance} ${acc?.currency})`,
                            value: acc?._id,
                          };
                        })}
                      name="tradingAccountId"
                      errorMessage={t("MT5 account is required")}
                      defaultValue={selectedAccount}
                      validate={{ required: { value: true } }}
                      label={t("MT5 Account")}
                      onChange={(e) => {
                        setSelectedAccount(e.value);
                        const chosenTradingAccount = accounts.find((account) => account._id === e.value);
                        // console.log("Conversion Rate", conversionRate);
                        setBaseCurrency(chosenTradingAccount?.currency);
                      }}
                    ></CustomSelect>
                  </div>
                </div>
              )}
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  className="btn btn-success waves-effect waves-light w-lg btn-sm"
                  disabled={
                    !isFirstStepValid || !bankAccount || !selectedAccount
                  }
                >
                  {props.t("Continue")}
                </Button>
              </div>
            </AvForm>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <>
          {
            <>
              <AvForm
                onValidSubmit={(e, v) => {
                  validateStep2(e, v);
                }}
              >
                <h6 className="mb-3">{props.t("Enter Amount")}</h6>
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <Label htmlFor="example-date-input" className="form-label">
                      {props.t("Amount")}
                    </Label>
                  </div>
                  <div>
                    {props.t("Transaction requirements")}
                    <i className="fas fa-info-circle ms-2"></i>
                  </div>
                </div>
                <InputGroup>
                  <Input
                    required
                    onChange={(e) => {
                      amountValidationHanlder(e);
                      setAmount(e.target.value);
                      console.log("gateway", gateway);
                    }}
                    className="form-control"
                    type="number"
                    min="0"
                    value={amount}
                    placeholder="Enter 30-128341"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
                {amountError && (
                  <p className="small text-danger ">
                    {" "}
                    {props.t("Please Select Account")}
                  </p>
                )}
                {amountValidation && (
                  <p className="small text-danger ">
                    {" "}
                    {props.t("The minimum value for amount is 30")}
                  </p>
                )}
                {/* <div className="text-center fw-bolder mt-4 received-amount">
                  <span className="fs-5">{selectedWallet?.label}</span>
                  <span className="fs-1">{amount}</span>
                </div> */}
                {amount && (gateway !== "PERFECT_MONEY" && gateway !== "CRYPTO") && <div className="text-center mt-4">
                  <span className="fs-5">
                    {t(
                      "Conversion Rate: " +
                        amount +
                        ` ${baseCurrency}` +
                        " = " +
                        `${parseFloat(
                          conversionRate?.toFixed(3) * amount
                        )?.toFixed(3)}` +
                        ` ${targetCurrency}`
                    )}
                  </span>
                </div>}
                <div className="my-4 text-center">
                  <Button
                    className="btn btn-primary m-2 btn-sm w-lg"
                    onClick={() => {
                      toggleTab(0);
                      setIsFirstStepValid(false);
                      setBankAccount({});
                      setSelectedAccountId("");
                      setSelectedAccount("");
                    }}
                  >
                    {props.t("Back")}
                  </Button>
                  <Button
                    className="btn btn-success m-2 btn-sm w-lg"
                    type="submit"
                    disabled={loading || amountValidation}
                  >
                    {props.t("Continue")}
                  </Button>
                </div>
              </AvForm>
            </>
          }
        </>
      ),
    },
    {
      header: "Withdraw status",
      content: (
        <>
          {result.status ? (
            <>
              <div className="text-center  mb-4">
                <h1 className="fs-1 mb-5">
                  {props.t("Yay!")} <i className="bx bx-wink-smile"></i>
                </h1>
                <p>{props.t("Pending Withdraw Thank You")}</p>
                {result.result?.freeMarginMessage && (
                  <p className="text-danger font-weight-bold">
                    {result.result?.freeMarginMessage}
                  </p>
                )}
                <p>
                  <span className="positive">
                    {result?.result?.amount["$numberDecimal"]}
                    {selectedWallet.label}
                  </span>
                </p>
              </div>
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-around px-4">
                  <div>
                    <div className="text-muted">{props.t("Status")}</div>
                    <div className="positive">{props.t("gateway")}</div>
                  </div>
                  <div>
                    <div className="text-muted">{result.result?.status}</div>
                    <div>{result.result?.gateway}</div>
                  </div>
                </div>
              </CardWrapper>
            </>
          ) : (
            <>
              <div className="text-center  mb-4">
                <h1 className="fs-1 mb-5">
                  {props.t("Oops!")} <i className="bx sad"></i>
                </h1>
                <p>
                  {props.t("Your Withdraw Request Not Successfully Created")}
                </p>
              </div>
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-between px-5">
                  <div>
                    <div className="positive">{result.message}</div>
                  </div>
                </div>
              </CardWrapper>
            </>
          )}
          <div className="text-center">
            <Button
              className="btn btn-danger m-2 btn-sm w-lg"
              onClick={() => {
                toggleOpen();
                setLoading(true);
              }}
              disabled={loading}
            >
              {props.t("Continue")}
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
        size={activeStep === 0 ? "xl" : "lg"}
      ></CustomModal>
      <AddBankAccountModal
        isOpen={addBankModal}
        toggleOpen={() => {
          setAddBankModal(false);
        }}
      ></AddBankAccountModal>
      <AddUsdtMethodModal
        isOpen={addUsdtModal}
        toggleOpen={() => {
          setAddUsdtModal(false);
        }}
      ></AddUsdtMethodModal>
    </>
  );
}
export default withTranslation()(Mt5Withdraw);
