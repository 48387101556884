/* eslint-disable no-debugger */
import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import {
  addDeposit,
  addForexDeposit,
  cancelFiniticPay,
  paymentGatewayDeposit,
} from "apis/deposit";
import { allowedMethods } from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import AsiaBanks from "./Methods/AsiaBanks";
import APForm from "./Methods/AsiaBanksForm";
import { fetchBankAccountsAPI, fetchCompanyBankAccounts } from "apis/bankAccounts";
import DepositStatus from "./DepositStatus";
import CustomSelect from "components/Common/CustomSelect";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import PerfectMoney from "./Methods/PerfectMoney/PerfectMoney";
import { MyFatoorah } from "./Methods/MyFatoorah";

function Deposit({ isOpen, toggleOpen, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  const { clientData } = useSelector(state => state.Profile);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [targetCurrency, setTargetCurrency] = useState("USD");
  const [localBanks, setLocalBanks] = useState([]);
  const [InternationalBanks, setInternationalBanks] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [minimumDepositAmount, setMinimumDepositAmount] = useState(15);
  const [minAmount, setMinAmount] = useState(minimumDepositAmount);
  const [maxAmount, setMaxAmount] = useState(0);

  const { accounts } = useSelector((state) => state.forex.accounts);
  const [selectedAccount, setSelectedAccount] = useState(null);


  useEffect(() => {
    dispatch(
      getAccountsStart({
        limit: 100
      })
    );
  }, []);


  useEffect(() => {
    const sWallet = wallets?.find((wallet) => wallet._id === selectedWallet?.value);
    const minAmount =
      parseInt(sWallet?.assetId?.minAmount?.deposit) > minimumDepositAmount
        ? parseInt(sWallet?.assetId?.minAmount?.deposit)
        : minimumDepositAmount;
    setMinAmount(minAmount);
  }, [selectedWallet, minimumDepositAmount]);


  useEffect(() => {
    if (wallets?.length > 0) {
      const w = wallets?.filter(w => !w?.depositDisabled);
      // setSelectedWallet({
      //   label: w[0]?.asset,
      //   value: w[0]?._id,
      //   image: getAssetImgSrc(w[0]?.assetId)
      // });
      const minAmount =
        parseInt(wallets[0].assetId?.minAmount?.deposit) > minimumDepositAmount
          ? parseInt(wallets[0].assetId?.minAmount?.deposit)
          : minimumDepositAmount;
      setMinAmount(minAmount);
    }
  }, [wallets, minimumDepositAmount]);


  const loadWallets = () => {
    dispatch(fetchWallets());
  };

  useEffect(() => {
    if (!wallets || wallets?.length === 0) {
      loadWallets({
        isCrypto: false,
        isInventory: false,
      });
    }
  }, []);

  const fiatWallets = wallets?.filter((wallet) => !wallet.depositDisabled).map((wallet) => {
    return {
      label: wallet?.asset,
      value: wallet?._id,
      image: getAssetImgSrc(wallet?.assetId)
    };
  });

  useEffect(async () => {
    const banks = await fetchCompanyBankAccounts({
      page: 1,
      limit: 10,
      bankType: "company"
    });
    if (banks.length > 0) {
      setInternationalBanks([...banks.filter((bank) => bank.isLocal === false)]);
      setLocalBanks([...banks.filter((bank) => bank.isLocal === true)]);
    }
  }, []);

  // Effects
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(getAccountsStart());
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
      setIsFirstStepValid(false);
    }
  }

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
        return (
          <WireTransfer
            t={t}
            setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency}
            setTargetCurrency={setTargetCurrency}
            isLocal={false}
            banks={InternationalBanks}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            setMinAmount={setMinAmount}
            setMaxAmount={setMaxAmount}
            type={type}
          />
        );
      case "LOCAL_WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} isLocal={true} banks={localBanks} />
        );
      case "ASIA_BANKS":
        return (
          <AsiaBanks type={type} t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} />
        );
      case "FINITIC_PAY":
      case "OLX_FOREX":
        return (
          <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} setMinAmount={setMinAmount} />
        );
      case "PERFECT_MONEY":
        return (
          <PerfectMoney t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} setTargetCurrency={setTargetCurrency} setMinAmount={setMinAmount} setMaxAmount={setMaxAmount} />
        );
      case "MYFATOORAH":
        return (
          <MyFatoorah
            t={t}
            setIsFirstStepValid={setIsFirstStepValid}
            setPaymentPayload={setPaymentPayload}
            paymentPayload={paymentPayload}
            setMinAmount={setMinAmount}
            setMaxAmount={setMaxAmount}
          />
        );
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return (
          <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "CRYPTO":
        return (
          <CompanyCrypto
            t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload}
            setBaseCurrency={setBaseCurrency} selectedAccount={selectedAccount} setMinAmount={setMinAmount} />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">
              {t("Please Select Payment Method")}
            </h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
    if (data && data instanceof FormData && data.get("gateway") === "LOCAL_WIRE_TRANSFER") {
      data.delete("gateway");
      data.append("gateway", "WIRE_TRANSFER");
    }
    if (gateway === "MYFATOORAH") {
      const { paymentPayload } = data;
      data.paymentMethodId = paymentPayload?.PaymentMethodId?.toString();
      data.paymentMethod = paymentPayload?.PaymentMethod;
      data.card = paymentPayload?.card || null;
      delete data.paymentPayload;
    }
    if (type === "fiatDeposit") {
      if (["FINITIC_PAY", "PERFECT_MONEY", "MYFATOORAH"].includes(selectedPaymentMethod)) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          setLoading(false);
          if (res.result.url && !["FINITIC_PAY"].includes(selectedPaymentMethod)) {
            window.location.href = res.result.url;
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else {
        addDeposit(data).then((res) => {
          setLoading(false);
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setLoading(false);
          setResult(err);
          toggleTab(2);
        });
      }

    } else if (type === "mt5Deposit") {
      if (["FINITIC_PAY", "PERFECT_MONEY", "MYFATOORAH"].includes(selectedPaymentMethod)) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          setLoading(false);
          if (res.result.url && !["FINITIC_PAY"].includes(selectedPaymentMethod)) {
            window.location.href = res.result.url;
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
        return;
      } else {
        addForexDeposit(data).then((res) => {
          setResult(res);
          toggleTab(2);
          setLoading(false);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      }
    }
  };
  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">
                {t("Select Payment Method")}
              </h6>
              <Row className="justify-content-center payment-methods">
                {
                  allowedMethods.filter((method) => method.allowed.includes(type)).map((method) => {
                    if (!method.showForCountries || (method.showForCountries && method.showForCountries.includes(clientData.country)))
                      return <Col key={method.gateway} xs={4} lg={2} className="my-2">
                        <button
                          type="button"
                          onClick={() => {
                            setGateway(method.gateway);
                            setSelectedPaymentMethod(method.gateway);
                            setIsFirstStepValid(false);

                          }}
                          onMouseEnter={() => { setHoveredPaymentMethod(method.gateway) }}
                          onMouseLeave={() => { setHoveredPaymentMethod() }}
                          className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4 d-flex align-items-center justify-content-center`}
                          style={{
                            maxWidth: "80px",
                            maxHeight: "80px",
                          }}
                        >
                          <img
                            src={method.image}
                            width="100%"
                            height="100%"
                            alt=""
                          ></img>
                        </button>
                        <div className="mt-3">
                          {method.gateway === "ASIA_BANKS" && clientData.country === "India" && (
                            <p>UPI India Online</p>
                          )}
                          {method.gateway === "ASIA_BANKS" && clientData.country === "Malaysia" && (
                            <p>Online Bank MYR</p>
                          )}
                          {method.gateway === "ASIA_BANKS" && clientData.country === "Indonesia" && (
                            <p>Online Bank IDR</p>
                          )}
                          {method.gateway === "ASIA_BANKS" && clientData.country === "Philippines" && (
                            <p>Online Bank PHP</p>
                          )}
                          {method.gateway === "ASIA_BANKS" && (clientData.country === "Viet Nam" || clientData.country === "Vietnam") && (
                            <p>Online Bank VND</p>
                          )}
                        </div>
                      </Col>;
                  })
                }
              </Row>
            </div>
            {gateway && stageOnePaymentDetails()}
            <div>
              <Row>
                {type === "fiatDeposit" && (
                  <div>
                    <label className="font-weight-bold" style={{
                      color: "#333",
                      fontSize: "1rem"
                    }}>
                      Select Wallet
                    </label>
                    <div className="mt-3">
                      <CustomSelect
                        placeholder="Select Wallet"
                        name="wallets"
                        isRequired
                        options={fiatWallets}
                        //defaultValue={""}
                        value={selectedWallet}
                        onChange={(e) => {
                          setSelectedWallet(e);
                        }}
                      />
                    </div>
                  </div>
                )}
                {type === "mt5Deposit" && gateway && (
                  <div>
                    <label className="font-weight-bold" style={{
                      color: "#333",
                      fontSize: "1rem"
                    }}>
                      Select Trading Account
                    </label>
                    <div className="mt-3">
                      <CustomSelect
                        placeholder="Select Trading Account"
                        options={
                          accounts
                            ?.filter(a => !["DEMO", "MAM_FEE", "IB"].includes(a.type))
                            ?.filter(a => (a.profile ? a.profile?.profileType === 9 : true))
                            ?.filter(a => !a.depositDisabled)
                            .map(acc => ({
                              label: `${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc.Balance} ${acc.currency})`,
                              value: acc._id
                            }))
                        }
                        name="tradingAccountId"
                        errorMessage={t("MT5 account is required")}
                        validate={{ required: { value: true } }}
                        label={t("MT5 Account")}
                        defaultValue={""}
                        onChange={(e) => {
                          setSelectedAccount(e?.value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </Row>
            </div>
            <div className="text-center mt-4">
              <Button
                type="submit"
                className="btn btn-success waves-effect waves-light w-lg btn-sm"
                disabled={
                  (gateway === "") ||
                  !isFirstStepValid ||
                  (type === "mt5Deposit" ? selectedAccount == null : selectedWallet == null)
                }
                onClick={() => {
                  toggleTab(1);
                }}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <StageTwo
          t={t}
          setLoading={setLoading}
          loading={loading}
          toggleTab={toggleTab}
          type={type}
          gateway={gateway}
          handleSubmit={submitHandler}
          paymentPayload={paymentPayload}
          receipt={allowedMethods.find((method) => method.gateway === gateway)?.receipt}
          targetCurrency={targetCurrency}
          baseCurrency={baseCurrency}
          setBaseCurrency={setBaseCurrency}
          setTargetCurrency={setTargetCurrency}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          minimumDepositAmount={minimumDepositAmount}
          setMinimumDepositAmount={setMinimumDepositAmount}
          minAmount={minAmount}
          maxAmount={maxAmount}
          selectedAccount={selectedAccount}
        />
      ),
    },
    {
      header: "Deposit status",
      content: <DepositStatus
        result={result}
        selectedPaymentMethod={selectedPaymentMethod}
        loading={loading}
        isSuccess={result?.isSuccess}
        t={t}
        toggleOpen={toggleOpen}
        type={type}
        isError={!result?.isSuccess}
      />,
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={() => {
          // if (activeStep === 2 && gateway === "FINITIC_PAY") {
          //   cancelFiniticPay(result?.result?.url);
          // }
          toggleOpen();
        }}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>

  );
}
export default withTranslation()(Deposit); 
