import { useEffect } from "react";

export default function OlxForex({setIsFirstStepValid, setMinAmount}) {
  useEffect(() => {
    setIsFirstStepValid(true);
    setMinAmount(20);

  }, [])
  return (
    <div>

    </div>
  );
} 