import { cpUrl, jwtSecret } from "content";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AvForm, AvField,
} from "availity-reactstrap-validation";
import {
  Row, Modal, ModalHeader, ModalBody,  Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";

import {
  toggleCurrentModal, showSuccessNotification,
} from "store/actions";
import jwt from "jsonwebtoken";
import { encryptData } from "common/encryptData";
import { getRebateRules } from "apis/rebateSettings";

const CreatePromotionLink = ({ isOpen, toggle, type, t }) => {
  const dispatch = useDispatch();
  const { clientData } = useSelector(state => state.Profile);
  const [selectedRebates, setSelectedRebates] = useState([]);
  const [rebateRules, setRebateRules] = useState([]);
  async function getRebateRulesFn() {
    const rebates = await getRebateRules({
      payload: {
        limit: 100000,
        page: 1
      }
    });
    console.log("setRebateRules", rebates);
    setRebateRules(rebates?.result?.docs);
  }

  useEffect(async() => {
    await getRebateRulesFn()
  }, []);
  useEffect(() => {
    setSelectedRebates([]);
  }, []);
  const handleRebateChange = (rebate, rebateValue) => {
    const { rebateId, rebateName, minRebateValue, rebateValue:rebateValueMax } = rebate;
    let modifiedRebates = [...selectedRebates];
    modifiedRebates = modifiedRebates.filter((rebate) => String(rebate?.rebateId) !== String(rebateId));
    modifiedRebates?.push({
      rebateId,
      rebateValue,
      rebateName,
      minRebateValue,
      maxRebateValue: rebateValueMax,
    });
    setSelectedRebates(modifiedRebates);
    // setSelectedRebates(prevState => {
    //   return [...prevState, { 
    //     rebateId,
    //     rebateValue,
    //     rebateName,
    //     minRebateValue,
    //     maxRebateValue: rebateValueMax,
    //   }];
    // });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedRebatesData = selectedRebates?.map(rebate=> {
      return {
        ...rebate,
        aggrementId: type?.agRef,
      };
    });
    const data = {
      [`${type?.promoLink?._id}`]: selectedRebatesData,
    };
    const encryptedData = encryptData(data);
    navigator.clipboard.writeText(`${cpUrl}/register/client/ib?rebateRef=${encryptedData}&promotionLink=${type?.promoLink?._id}&parentRef=${clientData?.recordId}`);
    dispatch(showSuccessNotification("Link copied to clipboard"));
    toggle();
  };
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4" className="text-capitalize">
        {t(`Create ${type?.text}`)}
      </ModalHeader>
      <ModalBody className="px-4">
        <AvForm
          onValidSubmit={(e, v) => {
            handleSubmit(e);
          }}
        >
          {
            clientData?.rebates?.[type?.promoLink?._id] && clientData?.rebates?.[type?.promoLink?._id]?.map((rebate) => {
              const rebateItem = rebateRules?.find(reb => String(reb?._id) === String(rebate?.rebateId));
              return  <>
                <Row className="border rounded-3 p-3 mt-3">
                  <h5 className="mb-3">{t(`Select ${rebateItem?.name} Rebate`)}</h5>
                  <AvField 
                    onChange={(e) => handleRebateChange(rebate, Number(e.target.value))} 
                    type="select" 
                    name={`rebate${rebate?.rebateId}`} 
                    value="" 
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {/* {[...Array(Math.round(Number(rebate?.rebateValue)))]?.map((_, index) => (
                      <option key={index} value={Number(rebate?.rebateValue) - index - 1}>
                        {`${Number(rebate?.rebateValue) - index - 1}`}
                      </option>
                    ))} */}
                    {
                      [
                        ...[...Array(Math.round(Number(rebate?.value)))].map((_, index) => (
                          <option key={`rebateValues${index}`} value={Number(rebate?.value) - index}>
                            {`${(Number(rebate?.value) - index).toFixed(2)}`}
                          </option>
                        )),
                        ...[
                          0.90, 0.80, 0.70, 0.60, 0.50, 0.40, 0.30, 0.20, 0.10, 0.00,
                        ].map((value, index) => (
                          (value <= rebate?.value || rebate?.value >= 1) &&
                            <option key={`staticValues${index}`} value={value}>
                              {value.toFixed(2)}
                            </option>
                        )),
                      ]
                    }
                  </AvField>
                </Row>
              </>;
            })
          }
          <div className="text-center mt-3 mb-1">
            <Button type="submit" className="color-bg-btn border-0 text-white w-25">{t("Submit")}</Button>
            <Button color="light" type="button" className="w-25 ms-3"
              onClick={() => dispatch(toggleCurrentModal(""))}
            >{t("Skip")}</Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal >
  );
};

export default withTranslation()(CreatePromotionLink);