import React, { createContext, useContext, useState } from "react";

// Create the UserDataProfile context
const UserDataProfileContext = createContext(null);

// Provider component
export const UserDataProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);



  return (
    <UserDataProfileContext.Provider
      value={{ profileData, setProfileData }}
    >
      {children}
    </UserDataProfileContext.Provider>
  );
};

// Custom hook to use the context
export const useUserDataProfile = () => {
  const context = useContext(UserDataProfileContext);
  if (!context) {
    throw new Error(
      "useUserDataProfile must be used within a UserDataProfileProvider"
    );
  }
  return context;
};
