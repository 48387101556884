import { AvField, AvForm } from "availity-reactstrap-validation";
import calculateFee from "helpers/calculateFee";
import { validateFile } from "helpers/validations/file";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  InputGroup,
  InputGroupText,
  Label
} from "reactstrap";
import FiatFormDetails from "./Fiat/FiatFormDetails";
import Mt5Details from "./MT5/Mt5Details";
import { getConversionRate } from "apis/conversionRate";
import { getFiniticPayFeesConfig } from "apis/deposit";


export default function StageTwo(props) {
  const {
    t,
    toggleTab,
    type,
    gateway,
    handleSubmit,
    receipt = false,
    loading,
    paymentPayload,
    setLoading,
    targetCurrency,
    baseCurrency,
    setBaseCurrency,
    setTargetCurrency,
    selectedWallet,
    minimumDepositAmount,
    setMinimumDepositAmount,
    minAmount,
    maxAmount,
    selectedAccount
  } = props;
  const [file, setFile] = useState({});
  const [amount, setAmount] = useState();
  const [amountValidation, setAmountValidation] = useState(false);
  const tranFeeGroupDetails = useSelector((state) => state.Profile?.clientData?.transactionFeeId);
  const [transactionFee, setTransactionFee] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [usdAmount, setUsdAmount] = useState(null);
  const [feesLoading, setFeesLoading] = useState(false);
  const [fees, setFees] = useState({});

  const PERFECT_MONEY_MIN_AMOUNT = 10;

  const amountValidationHandler = () => {
    if (gateway === "ASIA_BANKS" && usdAmount) {
      setAmountValidation(usdAmount >= 15);
    } else if (gateway === "PERFECT_MONEY" && amount) {
      setAmountValidation(amount >= PERFECT_MONEY_MIN_AMOUNT && amount <= maxAmount);
    } else if (gateway === "WIRE_TRANSFER" && amount) {
      setAmountValidation(amount >= minAmount);
    } else if (amount && maxAmount > 0) {
      setAmountValidation(amount >= minAmount && amount <= maxAmount);
    } else if (amount) {
      setAmountValidation(amount >= minAmount);
    }
  };

  // console.log("Initial Data base - target currency: ", baseCurrency, targetCurrency);
  useEffect(() => {
    if (gateway === "FINITIC_PAY") {
      setFeesLoading(true);
      getFiniticPayFeesConfig()
        .then((resp) => resp.result)
        .then((res) => setFees(res))
        .finally(() => setFeesLoading(false));
    }
  }, [gateway]);

  useEffect(() => {
    if (amount >= 0) {
      if (gateway === "FINITIC_PAY") {
        const { isPercentage, minDeposit, onChainfee, value, minValue } = fees;
        if (isPercentage) {
          const cFees = parseFloat((value * amount) / 100);
          const feeAmount = cFees <= parseFloat(minValue) ? parseFloat(minValue) : cFees;
          setTransactionFee(parseFloat(feeAmount).toFixed(2));
          console.log(`Setting minDeposit to ${parseFloat(minDeposit)}`);
          setMinimumDepositAmount(parseFloat(minDeposit));
        } else {
          setTransactionFee(parseFloat(onChainfee + minValue).toFixed(2));
        }
      } else {
        setTransactionFee(tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0);
        setMinimumDepositAmount(minAmount);
      }
      amountValidationHandler();
    }
  }, [tranFeeGroupDetails, amount, gateway, minAmount]);

  useEffect(() => {
    if (type === "fiatDeposit" && selectedWallet && selectedWallet?.symbol !== targetCurrency) {
      //console.log("selectedWallet: ", selectedWallet);
      console.log("base-Target Wallet stageTwo: ", selectedWallet?.label, targetCurrency);
      setBaseCurrency(selectedWallet?.label);
      // setTargetCurrency(selectedWallet?.symbol);
      getConversionRate({
        baseCurrency: selectedWallet?.label,
        targetCurrency: targetCurrency,
      }).then((res) => {
        setConversionRate(res);
      });
      console.log("Conversion Ratess ", conversionRate);
    } else {
      console.log("base-target TradingAcc stageTwo: ", baseCurrency, targetCurrency);
      getConversionRate({
        baseCurrency: "USD",
        targetCurrency: targetCurrency,
      }).then((res) => {
        setConversionRate(res);
      });
    }
  }, [baseCurrency, targetCurrency, selectedWallet]);

  const renderFormType = () => {
    switch (type) {
      case "fiatDeposit":
        return (
          <FiatFormDetails
            t={t}
            selectedWallet={selectedWallet}
            amount={amount}
            setAmount={setAmount}
            setAmountValidation={setAmountValidation}
            baseCurrency={baseCurrency}
            targetCurrency={targetCurrency}
            conversionRate={conversionRate}
            InputGroupTextgateway={gateway}
            setTargetCurrency={setTargetCurrency}
            setUsdAmount={setUsdAmount}
            usdAmount={usdAmount}
            amountValidation={amountValidation}
            minimumDepositAmount={minimumDepositAmount}
            minAmount={ minAmount }
            maxAmount={maxAmount}
          />
        );
      case "mt5Deposit":
        return (
          <Mt5Details
            t={t}
            amount={amount}
            setAmount={setAmount}
            amountValidation={amountValidation}
            setAmountValidation={setAmountValidation}
            baseCurrency={baseCurrency}
            targetCurrency={targetCurrency}
            conversionRate={conversionRate}
            gateway={gateway}
            setTargetCurrency={setTargetCurrency}
            setUsdAmount={setUsdAmount}
            usdAmount={usdAmount}
            minimumDepositAmount={minimumDepositAmount}
            minAmount={minAmount}
            maxAmount={maxAmount}

          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AvForm
      onValidSubmit={(e, v) => {
        setLoading(true);
        const formData = new FormData();
        if (type === "mt5Deposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(Number(amount).toFixed(2)));
            formData.append("note", "note");
            formData.append("tradingAccountId", selectedAccount);
            formData.append("receipt", file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload,
              amount: parseFloat(Number(amount).toFixed(2)),
              note: "note",
              tradingAccountId: selectedAccount,
            });
          }
        } else if (type === "fiatDeposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(Number(amount).toFixed(2)));
            formData.append("note", "note");
            formData.append("walletId", selectedWallet.value);
            formData.append("receipt", file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload,
              amount: parseFloat(Number(amount).toFixed(2)),
              note: "note",
              walletId: selectedWallet.value,
            });
          }
        }
      }}
    >
      <h6 className="mb-3">
        {t("Enter Amount")} {baseCurrency}
      </h6>
      <div className="d-flex justify-content-between mb-2">
        {renderFormType()}
      </div>
      <div className="mb-3 mt-3">
        <Label className="form-label mb-2">{t("Transaction Fee")}</Label>
        <InputGroup className="">
          <InputGroupText className=" w-100">
            {transactionFee ?? 0}
            {"   "}
            {selectedWallet?.asset}
          </InputGroupText>
        </InputGroup>
      </div>
      {receipt && (
        <div className="mb-3">
          <Label className="form-label" htmlFor="receipt">
            {t("Receipt")}
          </Label>
          <AvField
            type="file"
            name="receipt"
            className="form-control form-control-md"
            errorMessage={t("Receipt is required")}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            validate={{
              required: { value: true },
              validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                sizeValidationMessage: t("The file size is too large"),
                extensionValidationMessage: t(
                  "The file extension is not allowed"
                ),
              }),
            }}
          />
        </div>
      )}
      <div className="my-4 text-center">
        <Button
          className="btn btn-primary m-2 btn-sm w-lg"
          onClick={() => toggleTab(0)}
        >
          {t("Back")}
        </Button>
        <Button
          className="btn btn-success m-2 btn-sm w-lg"
          type="submit"
          disabled={!amountValidation || loading || !amount }
        >
          {t("Continue")}
        </Button>
      </div>
    </AvForm>
  );
}
