import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPT_DATA_SECRET_KEY;

export const encryptData = (data) => {
  data = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  return btoa(ciphertext);
};

export const decryptData = (data) => {
  const decodedCiphertext = atob(data);
  const bytes = CryptoJS.AES.decrypt(decodedCiphertext, secretKey);
  const finalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return finalData;
};

// Example usage
// const dataToEncrypt = { username: 'JohnDoe', password: '12345' };
// const encryptedData = encryptData(dataToEncrypt);
// const decryptedData = decryptData(encryptedData);
// console.log('Encrypted Data:', encryptedData);